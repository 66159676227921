import React, { useEffect, useRef, useState } from "react";
import { Prompt, useHistory } from "react-router-dom";
import { Backdrop, Box, Button, CircularProgress, FormControlLabel, IconButton, Switch, Typography } from "@material-ui/core";
import { ReactComponent as EditIcon } from '../../assets/icons/edit_dark.svg';
import { ReactComponent as BlueCheckmarkIcon } from '../../assets/icons/blue_checkmark.svg';
import { ReactComponent as SideBarOpenIcon } from '../../assets/icons/side_bar_open_icon.svg';
import TextField from "../../components/main/CustomFields/TextField";
import { getUrlAndParams, removeUrlParams } from "../../helpers/other";
import { getCampaignById, getCampaignEmails, triggerEmailGenerate, updateCampaign, updateCampaignEmail } from "../../redux/services/tactical-outreach";
import ConfirmationModal from "../../components/main/ConfirmationModal";
import { trimTextByCharacters } from "../../helpers/text";
import SideBar from "./components/SideBar";
import ContentEditor from "./components/ContentEditor";
import { convertHTMLToState, convertMarkdownToHtml, convertStateToHTML, isHTML, isMarkdown } from "./helpers";
import LimitationWidget from "../../components/main/LimitationWidget";
import { observableService } from "../../services/observable";
import ReplyGenerator from './components/ReplyGenerator';
import { getCurrentSubscription } from "../../redux/services/subscriptions";
import { getCurrentSubscriptionStatus } from "../../helpers/subscription";
import { getProfile } from "../../redux/services/profile";
import { showViewForAdmin } from "../../helpers/roles";
import { notify } from "../../providers/notification";
import { getCampaignComments } from "../../redux/services/my-library";

import { useStyles } from "./styles";
import { REVIEW_STATUS } from "../../utils/enums";

const TacticalOutreachEmail = (props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [campaign, setCampaign] = useState<any>(null);
  const [email, setEmail] = useState<any>(null);
  // const [replyTemplate, setReplyTemplate] = useState<any>(null);
  const [unsavedDataModal, setUnsavedDataModal] = useState<{ active: boolean, targetEmail: number }>({
    active: false,
    targetEmail: null,
  });
  const [editSubject, setEditSubject] = useState<{ value: string, field: string } | null>(null);
  const [sideBarOpen, setSideBarOpen] = useState<boolean>(true);
  const [leavingModal, setLeavingModal] = useState<{ active: boolean, nextLocation: string | null }>({
    active: false,
    nextLocation: null
  });
  const [editorState, setEditorState] = useState<any>(null);
  const [showGenerationFailed, setShowGenerationFailed] = useState<boolean>(false);
  const [showStopGeneration, setShowStopGeneration] = useState<boolean>(false);
  const [emailGeneratingProcessActive, setEmailGeneratingProcessActive] = useState<boolean>(false);
  // const [replyTemplateLoading, setReplyTemplateLoading] = useState<boolean>(false);
  const [subscription, setSubscription] = useState<any>(null);
  const [profile, setProfile] = useState<any>(null);

  const history = useHistory();
  const classes = useStyles();
  const containerRef = useRef(null);

  useEffect(() => {
    if (email) {
      const { params } = getUrlAndParams();
      params.set('email_id', email.id);

      window.history.replaceState(null, '', `${window.location.pathname}?${params.toString()}`);

      // setReplyTemplate(null);
    }
  }, [email]);

  useEffect(() => {
    if (email?.content?.length) {
      let content = email.content;

      if (!isHTML(content)) {
        content = convertMarkdownToHtml(content)
      }

      setEditorState(
        convertHTMLToState(content)
      );
    } else {
      setEditorState(
        convertHTMLToState('')
      );
    }
  }, [email?.content]);

  useEffect(() => {
    setLoading(true);

    const { params } = getUrlAndParams();
    const campaign_id = params.get('campaign_id');
    const email_id = params.get('email_id');

    if (!campaign_id || campaign_id === 'null') {
      notify.error('Campaign not found');

      history.push('/tactical-outreach');
    } else {
      handleGetCampaign(campaign_id, email_id);
    }

    handleGetProfile();
    handleGetSubscription();

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  const handleBeforeUnload = (event) => {
    event.preventDefault();
    event.returnValue = '';
  };

  const handleGetProfile = async () => {
    const { data, error } = await getProfile();

    if (data && !error) {
      setProfile(data);
    } else {
      notify.error('Can not find your profile data');

      history.push('/home');
    }
  }

  const handleGetSubscription = async () => {
    const { data, error } = await getCurrentSubscription();

    if (data && !error && getCurrentSubscriptionStatus(data) !== 'EXPIRED') {
      setSubscription(data);
    } else {
      notify.error('You have to be subscribed');

      history.push('/subscriptions');
    }
  }

  const handleGetCampaign = async (campaignId, emailId) => {
    const { data: campaign, error: campaignError } = await getCampaignById(campaignId);
    const { data: emails, error: emailsError } = await getCampaignEmails(campaignId);
    const { data: comments, error: commentsError } = await getCampaignComments(campaignId);

    if (!campaign || !emails || campaignError || emailsError) {
      history.push('/my-history');
    } else {
      // Deduplicate emails based on 'id'
      const uniqueEmails = emails.reduce((acc, current) => {
        if (!acc.some(item => item.id === current.id)) {
          acc.push(current);
        }
        return acc;
      }, []);

      // Map and sort the emails
      const processedEmails = uniqueEmails
        .map(item => ({
          ...item,
          status: item?.content?.trim()?.length ? "READY" : "ERROR",
        }))
        .sort((a, b) => a.order - b.order);

      const newCampaign = {
        ...campaign,
        emails: processedEmails,
        comments: comments && !commentsError ? comments : []
      };

      // Determine the target email
      const foundEmail = processedEmails.find(
        item => item.id === emailId || item.id === email?.id
      );

      const fallbackEmail =
        processedEmails[processedEmails.length - 1]?.status === "READY"
          ? processedEmails[processedEmails.length - 1]
          : email;

      const targetEmail = emailId && foundEmail
        ? foundEmail.status === "READY"
          ? foundEmail
          : fallbackEmail
        : fallbackEmail;

      // Handle MULTI_TOUCH_CAMPAIGNS logic
      if (campaign.subCategory === "MULTI_TOUCH_CAMPAIGNS" && campaign.status === "INCOMPLETED") {
        handleTriggerEmailGenerate(newCampaign, processedEmails, targetEmail);
      } else {
        setCampaign(newCampaign);

        setEmail({
          ...targetEmail,
          status: targetEmail?.content?.trim()?.length ? "READY" : "ERROR",
        });
      }

      setLoading(false);
    }
  }

  const handleSaveEmail = async () => {
    const { data, error } = await updateCampaignEmail({
      body: {
        ...email,
        content: convertStateToHTML(editorState)
      },
      id: email.id
    });

    if (data && !error) {
      handleGetCampaign(data.campaignId, data.id);

      notify.success('Changes saved successfully');
    } else {
      notify.error('Failed to save changes');
    }
  }

  const handleSaveEdit = async () => {
    if (
      (editSubject.field === 'name' && editSubject.value.trim().length > 50) ||
      (editSubject.field === 'description' && editSubject.value.trim().length > 300)
    ) {
      notify.error(`Max ${editSubject.field === 'name' ? '50' : '300'} characters`);
    } else {
      const { error } = await updateCampaign({
        body: {
          name: campaign.name,
          description: campaign.description,
          [editSubject.field]: editSubject.value
        },
        id: campaign.id
      });

      if (!error) {
        handleGetCampaign(campaign.id, email.id);

        notify.success('Campaign edited successfully');
      } else {
        notify.error('Failed to edit');
      }

      setEditSubject(null);
    }
  }

  const isSaveDisabled = () => {
    if (campaign && email) {
      if (email.status === 'ERROR') {
        return true;
      } else {
        const originalEmail = {
          subject: campaign.emails.find(item => item.id === email.id).subject,
          content: campaign.emails.find(item => item.id === email.id).content
        }

        originalEmail.content = isHTML(originalEmail.content)
          ? originalEmail.content
          : convertMarkdownToHtml(originalEmail.content)

        const currentEmail = {
          subject: email.subject,
          content: convertStateToHTML(editorState),
        }

        return JSON.stringify(originalEmail) === JSON.stringify(currentEmail);
      }
    } else {
      return true;
    }
  }

  const handleBlockedNavigation = (nextLocation) => {
    setLeavingModal({
      active: true,
      nextLocation: nextLocation.pathname + nextLocation.search
    })

    return false;
  };

  const handleTriggerEmailGenerate = async (campaign, emails, targetEmail) => {
    setEmailGeneratingProcessActive(true);

    // Remove existing placeholders (status: 'PENDING')
    const filteredEmails = emails.filter(item => item.status !== 'PENDING');

    // Calculate the number of additional emails needed
    const additionalEmailsCount = campaign.totalEmails - filteredEmails.length;

    // Generate new email placeholders
    const newPlaceholders = Array.from(Array(additionalEmailsCount).keys()).map(() => ({
      subject: '',
      content: '',
      createdAt: '',
      updatedAt: '',
      status: 'PENDING',
    }));

    // Create the new campaign object
    const newCampaign = {
      ...campaign,
      emails: [...filteredEmails, ...newPlaceholders],
    };

    setCampaign(newCampaign);
    setEmail({
      ...targetEmail,
      status: targetEmail?.content?.trim()?.length ? 'READY' : 'ERROR',
    });

    const { data, error } = await triggerEmailGenerate({
      campaignId: newCampaign.id,
      type: 'multi_touch',
    });

    if (data && !error) {
      observableService.sendEvent('Increase usage amount');

      setTimeout(() => {
        handleGetCampaign(newCampaign.id, email?.id);
      }, 500);

      if (data.metaData.index >= data.metaData.total) {
        setEmailGeneratingProcessActive(false);

        observableService.sendEvent('Refetch usage amount');

        notify.success(`All ${data.metaData.total} emails generated successfully`);
      }
    } else {
      setEmailGeneratingProcessActive(false);

      if (error?.status === 402) {
        observableService.sendEvent('Show top up modal for TACTICAL_OUTREACH');
      } else {
        setShowGenerationFailed(true);

        setCampaign({
          ...newCampaign,
          emails: [
            ...newCampaign.emails.filter(item => item.status === 'READY'),
            {
              subject: '',
              content: '',
              createdAt: '',
              updatedAt: '',
              status: 'ERROR',
            },
          ],
        });
      }
    }
  }

  return (
    <>
      <Prompt
        when={(campaign?.status === 'INCOMPLETED' || !isSaveDisabled()) && !loading}
        message={(location) => handleBlockedNavigation(location)}
      />

      <Backdrop
        style={{ color: '#fff', zIndex: 9999 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Box className={classes.wrapper}>
        <div className={classes.container} ref={containerRef}>
          {campaign && email && (
            <Box className={classes.header}>
              <Box
                className={classes.innerHeader}
                style={{
                  minHeight: editSubject ? '100px' : 'fit-content'
                }}
              >
                <Box className={classes.nameBlock}>
                  {editSubject?.field === 'name'
                    ? <Box style={{ width: '80%', display: 'flex', alignItems: 'center', gap: '8px' }}>
                        <TextField
                          value={editSubject.value}
                          placeholder={'Name'}
                          onChange={(event) => {
                            setEditSubject({ ...editSubject, value: event.target.value })
                          }}
                        />
                        <IconButton
                          size='small'
                          onClick={handleSaveEdit}
                        >
                          <BlueCheckmarkIcon />
                        </IconButton>
                      </Box>
                    : <Box style={{ width: '100%', display: 'flex', alignItems: 'center', gap: '8px' }}>
                        <Typography noWrap style={{ fontSize: '16px', fontWeight: '600', lineHeight: '19px' }}>
                          {trimTextByCharacters(campaign.name, 50)}
                        </Typography>
                        {(campaign.createdBy === profile.user.uuid || showViewForAdmin(subscription)) && (
                          <IconButton
                            size="small"
                            onClick={() => {
                              if (campaign.status === 'INCOMPLETED') {
                                setShowStopGeneration(true);
                              } else {
                                setEditSubject({ field: 'name', value: campaign.name })
                              }
                            }}
                          >
                            <EditIcon />
                          </IconButton>
                        )}
                      </Box>
                  }
                  {editSubject?.field === 'description'
                    ? <Box style={{ width: '80%', display: 'flex', alignItems: 'center', gap: '8px' }}>
                        <TextField
                          value={editSubject.value}
                          placeholder={'Name'}
                          onChange={(event) => {
                            setEditSubject({ ...editSubject, value: event.target.value })
                          }}
                        />
                        <IconButton
                          size='small'
                          onClick={handleSaveEdit}
                        >
                          <BlueCheckmarkIcon />
                        </IconButton>
                      </Box>
                    : <Box style={{ width: '100%', display: 'flex', alignItems: 'center', gap: '8px' }}>
                        <Typography noWrap style={{ fontSize: '12px', fontWeight: '600', lineHeight: '14px', color: '#475569' }}>
                          {trimTextByCharacters(campaign.description, 50)}
                        </Typography>
                        {(campaign.createdBy === profile.user.uuid || showViewForAdmin(subscription)) && (
                          <IconButton
                            size='small'
                            onClick={() => {
                              if (campaign.status === 'INCOMPLETED') {
                                setShowStopGeneration(true);
                              } else {
                                setEditSubject({ field: 'description', value: campaign.description })
                              }
                            }}
                          >
                            <EditIcon />
                          </IconButton>
                        )}
                      </Box>
                  }
                </Box>
                {campaign.type && (
                  <Typography
                    className={classes.tag}
                    style={{
                      color: campaign.category === 'MARKETING' ? '#57BE99' : campaign.category === 'SALES' ? '#D93855' : '#3A84C9',
                      borderColor: campaign.category === 'MARKETING' ? '#57BE99' : campaign.category === 'SALES' ? '#D93855' : '#3A84C9',
                    }}
                  >
                    {campaign.type.toLowerCase()?.replace(/_/g, ' ')?.replace(/\b\w/g, char => char.toUpperCase())}
                  </Typography>
                )}
                <Box style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
                  <Button
                    variant="outlined"
                    color="secondary"
                    startIcon={<EditIcon />}
                    style={{
                      whiteSpace: 'nowrap'
                    }}
                    onClick={() => {
                      if (campaign.status === 'INCOMPLETED') {
                        setShowStopGeneration(true);
                      } else {
                        history.push(`/tactical-outreach/builder?campaign_id=${campaign.id}`)
                      }
                    }}
                  >
                    {campaign.createdBy !== profile.user.uuid && !showViewForAdmin(subscription)
                      ? 'View '
                      : 'Edit '
                    }
                    {campaign.subCategory === 'MULTI_TOUCH_CAMPAIGNS'
                      ? `campaign parameters`
                      : `parameters`
                    }
                  </Button>
                  &nbsp;
                  <LimitationWidget
                    type='TACTICAL_OUTREACH'
                  />
                  {!sideBarOpen && (
                    <>
                      &nbsp;
                      &nbsp;
                      &nbsp;
                      <IconButton onClick={() => setSideBarOpen(true)}>
                        <SideBarOpenIcon />
                      </IconButton>
                    </>
                  )}
                </Box>
              </Box>
            </Box>
          )}

          {campaign && email && (
            <>
              <ContentEditor
                campaign={campaign}
                email={email}
                profile={profile}
                editorState={editorState}
                subscription={subscription}
                setEmail={setEmail}
                isSaveDisabled={isSaveDisabled}
                handleSaveEmail={handleSaveEmail}
                handleGetCampaign={handleGetCampaign}
                setEditorState={setEditorState}
                setLoading={setLoading}
                setShowStopGeneration={setShowStopGeneration}
              />

              {campaign.status === 'COMPLETED' && campaign.reviewStatus === REVIEW_STATUS.APPROVED && (
                <ReplyGenerator
                  email={email}
                  containerRef={containerRef}
                />
              )}
            </>
          )}
        </div>
        {campaign && email && (
          <SideBar
            open={sideBarOpen}
            email={email}
            campaign={campaign}
            profile={profile}
            subscription={subscription}
            isSaveDisabled={isSaveDisabled}
            setSideBarOpen={setSideBarOpen}
            setEmail={setEmail}
            setCampaign={setCampaign}
            setUnsavedDataModal={setUnsavedDataModal}
            handleGetCampaign={handleGetCampaign}
            setLoading={setLoading}
            setShowStopGeneration={setShowStopGeneration}
          />
        )}
      </Box>

      <ConfirmationModal
        open={unsavedDataModal.active}
        title={"You have unsaved data!"}
        description={"Do you want to save latest changes?"}
        onClose={() => setUnsavedDataModal({ active: false, targetEmail: null })}
        rejectBtnText={"No"}
        confirmBtnText={"Yes"}
        onReject={() => {
          setEmail(unsavedDataModal.targetEmail)
          setUnsavedDataModal({ active: false, targetEmail: null })
        }}
        onConfirm={async () => {
          await handleSaveEmail()
          setEmail(unsavedDataModal.targetEmail)
          setUnsavedDataModal({ active: false, targetEmail: null })
        }}
      />

      <ConfirmationModal
        open={leavingModal.active}
        title={
          emailGeneratingProcessActive
            ? "This action will stop email generating process!"
            : "Would you like to save your email before leaving?"
        }
        description={
          emailGeneratingProcessActive
            ? "Are you sure you want to stop the process and leave the page?"
            : null
        }
        onClose={() => setLeavingModal({
          active: false,
          nextLocation: null
        })}
        reversedButtons
        rejectBtnText={"Yes"}
        confirmBtnText={"No"}
        onReject={() => {
          setLoading(true);
          handleSaveEmail();
          setLeavingModal({
            ...leavingModal,
            active: false,
          });

          setTimeout(() => {
            history.push(leavingModal.nextLocation);
          }, 500);
        }}
        onConfirm={() => {
          if (emailGeneratingProcessActive) {
            setLeavingModal({
              active: false,
              nextLocation: null
            });
          } else {
            setLoading(true);
            setLeavingModal({
              active: false,
              nextLocation: null
            });

            setTimeout(() => {
              history.push(leavingModal.nextLocation);
            }, 500);
          }
        }}
      />

      <ConfirmationModal
        open={showGenerationFailed}
        title={"This campaign isn't completed yet"}
        description={"Continue the process of emails generating?"}
        onClose={() => setShowGenerationFailed(false)}
        rejectBtnText={"Continue"}
        onReject={() => {
          handleGetCampaign(campaign.id, email.id);
          setShowGenerationFailed(false);
        }}
      />

      <ConfirmationModal
        open={showStopGeneration}
        title={"You can't performe this action!"}
        description={campaign?.emails?.some(item => item.status === 'PENDING')
          ? "Wait for generating all emails to performe this action!"
          : "You have to generate all emails before to performe this action!"
        }
        onClose={() => setShowStopGeneration(false)}
        // confirmBtnText={"Close"}
        rejectBtnText={campaign?.emails?.some(item => item.status === 'PENDING')
          ? "Ok"
          : "Continue generation"
        }
        onReject={() => {
          if (campaign?.emails?.some(item => item.status === 'PENDING')) {
            setShowStopGeneration(false);
          } else {
            setShowStopGeneration(false);
            handleGetCampaign(campaign.id, email.id);
          }
        }}
      // onConfirm={() => setShowStopGeneration(false)}
      />
    </>
  );
};

export default TacticalOutreachEmail;
