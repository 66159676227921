import React from 'react';
import _ from 'lodash';
import { Box, Typography } from '@material-ui/core';
import TextField from '../../../../components/main/CustomFields/TextField';

import { useStyles } from './styles';
import Dropdown from '../../../../components/main/CustomFields/Dropdown';
import { REVIEW_STATUS } from '../../../../utils/enums';

interface Props {
  filterTerm: string[];
  searchTerm: string;
  isOnboardingCompleted: boolean;
  isSubscriptionActive: boolean;
  setFilterTerm: (options: string[]) => void;
  handleSearchChange: (event: any) => void;
}

const Filters: React.FC<Props> = ({
  filterTerm,
  searchTerm,
  isOnboardingCompleted,
  isSubscriptionActive,
  setFilterTerm,
  handleSearchChange
}) => {
  const classes = useStyles();
  // const options = [
  //   { value: REVIEW_STATUS.PENDING_REVIEW, label: 'Pending review' },
  //   { value: REVIEW_STATUS.APPROVED, label: 'Approved' },
  //   { value: REVIEW_STATUS.CHANGE_REQUIRED, label: 'Changes required' },
  //   { value: REVIEW_STATUS.REJECTED, label: 'Rejected' },
  // ]

  return (
    <Box className={classes.filters}>
      <Box className={classes.filtersLeftBlock}>
        {/* <Dropdown
          value={filterTerm.length 
            ? filterTerm.map(item => {
                return options.find(opt => opt.value === item).label
              })
            : ['All']
          }
          options={options}
          placeholder={'Select statuses'}
          optionsType={'checkboxes'}
          onChange={(event, value) => {
            let newValue = [];

            value
              .filter(item => item !== 'All')
              .forEach(item => {
                newValue.push(
                  options.find(opt => opt.label === item).value
                )
              })

            setFilterTerm(newValue)
          }}
        /> */}
      </Box>
      <Box 
        className={classes.filtersRightBlock}
        style={{
          opacity: (!isOnboardingCompleted || !isSubscriptionActive) ? '0.5' : '1',
          pointerEvents: (!isOnboardingCompleted || !isSubscriptionActive) ? 'none' : 'all',
        }}
      >
        <TextField
          type={'search'}
          placeholder={'Search campaigns'}
          value={searchTerm}
          showClear
          onChange={handleSearchChange}
          onSearchPress={() => { }}
          onKeyPress={() => { }}
        />
      </Box>
    </Box>
  );
};

export default Filters;
