import React, { useEffect, useRef, useState } from 'react';
import * as Yup from "yup";
import { Backdrop, Box, Button, CircularProgress, IconButton, Typography } from '@material-ui/core';
import { InsertDriveFile, DeleteOutline } from '@material-ui/icons';
import { Formik, Form } from 'formik';
import RadioButton from '../../components/main/CustomFields/RadioButton';
import UploadZone from '../../components/main/CustomFields/UploadZone';
import Dropdown from '../../components/main/CustomFields/Dropdown';
import { getFileExtension, convertFromBytes } from '../../helpers/file';
import { getFileTypesData, getIndustries, getJobRoles, getTeamSize } from '../../redux/services/common';
import { getCompanyIntelAttachments } from '../../redux/services/company-intel';
import { getProfile, updateProfile } from '../../redux/services/profile';
import { useHistory } from 'react-router-dom';
import StorageProvider from '../../framework/main/StorageProvider';
import { getUrlAndParams } from '../../helpers/other';
import TextField from '../../components/main/CustomFields/TextField';
import { uploadOnboardingInformation } from '../../redux/services/onboarding';
import { InfoOutlined } from "@material-ui/icons";
import InfoHighlightIcon from './components/InfoHighlightIcon';
import InfoHighlightModal from './components/InfoHighlightModal';
import { ReactComponent as PebblesLogo } from '../../assets/icons/pebbles_full_logo_black.svg';

import { useStyles } from './styles';
import { notify } from '../../providers/notification';

const urlRegex = /((https?):\/\/)?(www\.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(\/[a-zA-Z0-9#-]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;

const websiteWayValidationSchema = Yup.object().shape({
  website: Yup.string()
    .nullable()
    .matches(urlRegex, "This link is invalid.")
    .required("Please enter company website."),
});

const documentsWayValidationSchema = Yup.object().shape({
  data: Yup.object().test(
    "has-non-empty-property",
    "Please choose file type",
    (object: { [key: string]: number }) => {
      const errors: any = {};

      Object.entries(object).forEach(([key, value]) => {
        if (value <= 0) {
          errors[key] = "Please choose file type";
        }
      });

      if (Object.keys(errors).length === 0) {
        return true;
      }

      throw new Yup.ValidationError(errors, object, "data");
    }
  ),
});

const defaultAmount = 3;

const UploadCompanyDocuments = ({
  view,
  onlyFiles,
  onClose,
  onAfterClose,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [hintVisible, setHintVisible] = useState<boolean>(true);
  const [showHintModal, setShowHintModal] = useState<boolean>(false);
  const [webFiles, setWebFiles] = useState<any[]>([]);
  const [fileOptions, setFileOptions] = useState<any[]>([]);
  const [way, setWay] = useState<'website' | 'documents'>("website");
  const [error, setError] = useState<string>('');
  const [allowedAmount, setAllowedAmount] = useState<number>(defaultAmount);

  const history = useHistory();
  const classes = useStyles();
  const formRef = useRef(null);

  useEffect(() => {
    const initialize = async () => {
      setLoading(true);

      await getProfileData();
      await fetchCompanyTypes();

      setLoading(false);
    };

    initialize();
  }, []);

  useEffect(() => {
    if (onlyFiles) {
      setWay('documents');
    }
  }, [onlyFiles]);

  const fetchCompanyTypes = async () => {
    const { data } = await getFileTypesData({ file_type: "About your company" });

    if (Array.isArray(data)) {
      const options = data.map((type) => ({
        value: type.id,
        label: type.description
      }));

      setFileOptions(options);
      fetchCompanyIntelAttachments(options);
    }
  };

  const fetchCompanyIntelAttachments = async (options) => {
    const { data, error } = await getCompanyIntelAttachments({
      fileName: "",
      informationType: "COMPANY"
    });

    if (data && !error) {
      setAllowedAmount((prevState) => prevState - data.length);
      setFileOptions(
        options.filter(item => !data.find(doc => doc.informationTypeDescription === item.label))
      );
    }
  };

  const getProfileData = async () => {
    const { data } = await getProfile(null, true);

    if (data && formRef.current) {
      formRef.current.setFieldValue("website", data.account.webLink);
    }
  };

  const handleFinishUploading = async () => {
    await StorageProvider.removeItem("isEdit");

    if (view === "modal") {
      onClose && onClose();
      onAfterClose && onAfterClose();
    } else {
      history.push("/onboarding");
    }
  };

  const skipNow = async () => {
    setLoading(true);

    const { data: profile } = await getProfile();
    const { data: jobRoles } = await getJobRoles();
    const { data: industries } = await getIndustries();
    const { data: teamSizes } = await getTeamSize();

    await updateProfile({
      accountId: profile.account.uuid,
      companyName: profile.account.companyName,
      roleId: jobRoles.find((item) => item.name === profile.user.jobRole)?.id,
      industryId: industries.find((item) => item.industryName === profile.account.companyIndustry)?.id,
      gtmTeamSizeId: teamSizes.find((item) => item.size === profile.account.gtmTeamSize)?.id,
      website: "",
      location: profile.account.countryName,
    });

    StorageProvider.removeItem("isEdit");
    history.push("/onboarding");

    setLoading(false);
  };

  const getColor = (name: string) => {
    if (name.includes(".pdf")) {
      return "#E04E4E";
    }

    if (name.includes(".docx")) {
      return "#3166ED";
    }

    return "#00B84A";
  };

  const onChangeFile = (files?: FileList, erroneous?: { file: File, message: string }[]) => {
    if (erroneous?.length) {
      setError(`${erroneous.length} file${erroneous.length > 1 ? 's' : ''} failed`);
    } else if (webFiles.length >= allowedAmount) {
      setError('You can upload 3 documents max');
    } else {
      setError('')
    }

    const tempFile = [];

    Array.from(files).slice(0, !webFiles.length ? allowedAmount : Math.abs(webFiles.length - allowedAmount)).forEach((_file) => {
      const file: any = _file;
      file.information_type = 0;

      tempFile.push(file);
    });

    const newList = [...webFiles, ...tempFile];

    setWebFiles(newList);
  };

  const uploadMultipleFile = async (values) => {
    if (way === 'website') {
      setLoading(true);

      const { data: profile } = await getProfile();
      const { data: jobRoles } = await getJobRoles();
      const { data: industries } = await getIndustries();
      const { data: teamSizes } = await getTeamSize();

      await updateProfile({
        accountId: profile.account.uuid,
        companyName: profile.account.companyName,
        roleId: jobRoles.find(item => item.name === profile.user.jobRole)?.id,
        industryId: industries.find(item => item.industryName === profile.account.companyIndustry)?.id,
        gtmTeamSizeId: teamSizes.find(item => item.size === profile.account.gtmTeamSize)?.id,
        website: values.website,
        location: profile.account.countryName
      });

      handleFinishUploading();
    } else {
      if (!webFiles.length || webFiles.length > allowedAmount) {
        const message = !webFiles.length ? "Please attach file" : "You can upload 3 documents max"

        setError(message);

        setTimeout(() => {
          setError('');
        }, 5000);

        return;
      }

      setLoading(true);

      const promises = webFiles.map(async file => {
        return await uploadOnboardingInformation({
          file,
          type: fileOptions.find(item => item.value === file.information_type).label
        });
      });

      Promise.allSettled(promises).then(res => {
        const erroneous = res.filter((item: any) => item.status === 'rejected' || 'error' in item.value);
        const successful = res.filter((item: any) => item.status === 'fulfilled' && !('error' in item.value));

        if (erroneous.length) {
          notify.error(`${erroneous.length} document(s) failed in the uploading process`);
        }

        if (successful.length) {
          notify.success(`${successful.length} document(s) uploaded successfully`);
        }

        setLoading(false);

        if (successful.length) {
          handleFinishUploading();
        }
      });
    }
  };

  const removeFileWeb = (fileId: string) => {
    setWebFiles(
      webFiles.filter((_file) => _file.id !== fileId)
    );
  };

  const handleChangeFileInfo = (fileId: string, value: number) => {
    setError('');

    const index = webFiles.findIndex((file) => file.id === fileId);

    const newWebFiles = [...webFiles];
    newWebFiles[index].information_type = value;

    setWebFiles(newWebFiles);
  };

  return (
    <>
      <Backdrop
        style={{ color: '#fff', zIndex: 9999 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Box className={view === 'modal' ? classes.container2 : classes.container}>
        {(!view || view !== 'modal') && (
          <PebblesLogo
            className={classes.logo}
          />
        )}

        <Box className={classes.inner}>
          <Formik
            innerRef={formRef}
            validateOnChange={false}
            initialValues={{
              website: '',
              data: {},
            }}
            validationSchema={way === 'website'
              ? websiteWayValidationSchema
              : documentsWayValidationSchema
            }
            onSubmit={uploadMultipleFile}
          >
            {({
              errors,
              values,
              touched,
              setTouched,
              validateField,
              setFieldValue,
              setErrors
            }) => (
              <Box
                className={classes.form}
                style={{
                  padding: (!view || view !== 'modal') ? '40px' : '0px 20px'
                }}
              >
                <Box className={classes.formTop}>
                  <Typography component="h1" className={classes.formHeading}>
                    Provide your company information
                  </Typography>

                  {!loading && (!view || view !== 'modal') && (
                    <Box className={classes.formHeadingIcon}>
                      {hintVisible
                        ? <InfoHighlightIcon
                          onClose={() => setHintVisible(false)}
                          onShowModal={() => setShowHintModal(true)}
                        />
                        : <IconButton
                          onClick={() => setShowHintModal(true)}
                        >
                          <InfoOutlined
                            className={classes.highlightIcon}
                          />
                        </IconButton>
                      }
                    </Box>
                  )}
                </Box>

                <Typography
                  component="span"
                  className={classes.formDescription}
                >
                  This trains your own dedicated AI, familiarising it with your business.
                  The more information you provide, the more intelligent it becomes.
                </Typography>

                {!onlyFiles && (
                  <Box style={{ display: 'flex', flexDirection: 'column', gap: '4px', width: '100%', margin: '45px 0px 32px 0px' }}>
                    <RadioButton
                      checked={way === 'website'}
                      label={"Enter your website URL:"}
                      onChange={() => {
                        setWay('website')
                        setErrors({})
                      }}
                    />
                    <Box
                      style={{
                        opacity: way === 'documents' ? '0.5' : '1',
                        pointerEvents: way === 'documents' ? 'none' : 'all'
                      }}
                    >
                      <TextField
                        name={'website'}
                        value={values['website']}
                        placeholder={'Company website'}
                        error={(touched['website'] && errors['website']) as string}
                        onChange={(e) => setFieldValue('website', e.target.value)}
                      />
                    </Box>
                  </Box>
                )}

                <Box
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '4px',
                    width: '100%',
                    marginTop: onlyFiles ? '40px' : '0'
                  }}>
                  {!onlyFiles && (
                    <RadioButton
                      checked={way === 'documents'}
                      label={"Or, upload your company info as a document:"}
                      onChange={() => {
                        setWay('documents')
                        setErrors({})
                      }}
                    />
                  )}
                  {webFiles.length < defaultAmount && (
                    <Box
                      style={{
                        opacity: way === 'website' ? '0.5' : '1',
                        pointerEvents: way === 'website' ? 'none' : 'all'
                      }}
                    >
                      <UploadZone
                        formats={['pdf', 'docx', 'txt']}
                        size={{
                          number: 25,
                          unit: 'MB',
                        }}
                        maxFiles={3}
                        error={error}
                        multiple
                        onChange={(successful, erroneous) => {
                          if (successful.length || erroneous.length) {
                            onChangeFile(successful, erroneous);

                            if (successful.length) {
                              let newData = { ...values.data };
                              Array.from(successful).slice(0, allowedAmount).forEach((file: any) => {
                                newData = { ...newData, [file.id]: 0 };
                              });

                              setFieldValue("data", newData);
                            }
                          }
                        }}
                      />
                    </Box>
                  )}
                </Box>

                <Form className={classes.fileWrapper} translate={undefined}>
                  <Box
                    className={classes.fileScrollview}
                    style={{
                      opacity: way === 'website' ? '0.5' : '1',
                      pointerEvents: way === 'website' ? 'none' : 'all'
                    }}
                  >
                    {webFiles.map((file) => {
                      return (
                        <Box
                          key={file.id}
                          className={classes.itemWrapper}
                        >
                          <InsertDriveFile
                            className={classes.fileIcon}
                            style={{
                              color: getColor(file.name),
                            }}
                          />
                          <Box className={classes.fileContent}>
                            <Box className={classes.fileInfo}>
                              <Typography noWrap style={{ fontSize: '16px', fontWeight: '600', lineHeight: '19px' }}>
                                {file.name}
                              </Typography>

                              <Typography style={{ fontSize: '12px', fontWeight: '500', lineHeight: '15px', color: '#475569' }}>
                                {getFileExtension(file.type) +
                                  " • " +
                                  convertFromBytes(file.size)}
                              </Typography>
                            </Box>

                            <Box className={classes.fileSelectInner}>
                              <Dropdown
                                name={file.id}
                                value={fileOptions.find(item => item.value === values.data[file.id])?.label}
                                options={fileOptions.filter(item => !Object.values(values.data).includes(item.value))}
                                placeholder={'Choose file type'}
                                onChange={(event) => {
                                  const data: any = values.data;
                                  data[file.id] = Number(event.target.value);

                                  setFieldValue("data", data);
                                  setTouched(
                                    {
                                      data: {
                                        ...touched.data as object,
                                        [file.id]: true,
                                      },
                                    },
                                    false
                                  );
                                  setErrors({
                                    data: {
                                      ...errors.data as object,
                                      [file.id]: '',
                                    }
                                  });

                                  handleChangeFileInfo(
                                    file.id,
                                    Number(event.target.value)
                                  );
                                }}
                              />

                              {errors.data &&
                                (touched.data as any)[file.id] ? (
                                <Typography
                                  component="span"
                                  className={classes.errorMessage}
                                >
                                  {(errors.data as any)[file.id]}
                                </Typography>
                              ) : (
                                <></>
                              )}
                            </Box>
                          </Box>

                          <IconButton
                            className={classes.deleteBtn}
                            onClick={() => {
                              const data: any = values.data;
                              delete data[file.id];

                              setFieldValue("data", data);
                              removeFileWeb(file.id);
                            }}
                          >
                            <DeleteOutline
                              width={14}
                              height={18}
                            />
                          </IconButton>
                        </Box>
                      );
                    })}
                  </Box>

                  <Box className={classes.action}>
                    <Button
                      fullWidth
                      color="secondary"
                      variant="contained"
                      onClick={() => {
                        view === 'modal'
                          ? onClose()
                          : skipNow()
                      }}
                    >
                      {view === 'modal'
                        ? 'Cancel'
                        : 'Skip'
                      }
                    </Button>
                    <Button
                      fullWidth
                      color="primary"
                      variant="contained"
                      type="submit"
                      onClick={() => {
                        if (!Object.keys(values.data)?.length && way === 'documents') {
                          setError('Please attach file');
                        }
                      }}
                    >
                      Save & Continue
                    </Button>
                  </Box>
                </Form>
              </Box>
            )}
          </Formik>
        </Box>
      </Box>

      <InfoHighlightModal
        open={showHintModal}
        onClose={() => setShowHintModal(false)}
      />
    </>
  );
};

export default UploadCompanyDocuments;
