import { api, createApiCall } from "../api";
import {
  CompanyDetailsRequest,
  CompanyDetailsResponse,
  OrgInfoRequest, OrgInfoResponse
} from "../../interfaces/onboarding";

const onboardingAPI = api.injectEndpoints({
  endpoints: (builder) => ({
    getOnboardingData: builder.query<CompanyDetailsResponse[], void>({
      query: () => ({
        url: "/v1/onboarding/company_details",
        method: "GET"
      }),
    }),
    saveOnboardingData: builder.mutation<CompanyDetailsResponse, CompanyDetailsRequest>({
      query: (body) => ({
        url: "/v1/onboarding/company_details",
        method: "POST",
        body
      }),
      // transformResponse: (response: { data: any }) => response.data.attributes
    }),
    uploadOnboardingInformation: builder.mutation<void, { file: any, type: string }>({
      query: ({ file, type }) => {
        const formData = new FormData();
        formData.append("fileAttachments", file);

        return {
          url: `/v1/onboarding/attachments?fileType=${type}`,
          method: "POST",
          body: formData
        };
      }
    }),
    triggerAutofillOnboarding: builder.mutation<OrgInfoResponse[], OrgInfoRequest>({
      query: (body) => ({
        url: "/v1/onboarding/intels",
        method: "POST",
        body
      })
    }),
    getOnboardingCompleteness: builder.query<any, void>({
      query: () => ({
        url: "/v1/onboarding/company_details/completeness",
        method: "GET"
      }),
    })
  }),
  overrideExisting: true
});

// Create a function to call the API without using the hook
export const getOnboardingData = createApiCall<CompanyDetailsResponse[], void>(onboardingAPI.endpoints.getOnboardingData);
export const saveOnboardingData = createApiCall<CompanyDetailsResponse, CompanyDetailsRequest>(onboardingAPI.endpoints.saveOnboardingData);
export const uploadOnboardingInformation = createApiCall<void, { file: any, type: string }>(onboardingAPI.endpoints.uploadOnboardingInformation);
export const triggerAutofillOnboarding = createApiCall<OrgInfoResponse[], OrgInfoRequest>(onboardingAPI.endpoints.triggerAutofillOnboarding);
export const getOnboardingCompleteness = createApiCall<any, void>(onboardingAPI.endpoints.getOnboardingCompleteness);
