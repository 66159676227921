import { isDateInPast } from "./date";

export const getCurrentSubscriptionStatus = (subscription) => {
  if (subscription) {
    if (isDateInPast(subscription.validTill)) {
      return 'EXPIRED';
    } else if (subscription.isActive) {
      return 'ACTIVE';
    } else {
      return 'CANCELED';
    }
  } else {
    return 'ABSENT';
  }
}
