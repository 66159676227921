import * as Yup from "yup";

export const generateYupSchema = (template, parameters) => {
  const shape = {};

  template.sections.forEach(section => {
    const subSections = section.subSections;
    const alternativeSubSections = section.attributes.filter(item => item.type === 'SEPARATED_QUESTION_ATTRIBUTE').map(item => ({
      ...item.question,
      key: item.key
    }));

    [...subSections, ...alternativeSubSections].forEach(subSection => {
      subSection.elements.forEach(element => {
        const parentName = `${section.id}-${subSection.id}`;
        const targetName = subSection.key || `${parentName}-${element.id}`;
        const maxElementsAttribute = subSection.attributes.find(item => item.type === 'MAX_ELEMENTS_ATTRIBUTE');
        const hideUnderConditionAttributes = subSection.attributes.filter(item => item.type === 'HIDE_UNDER_CONDITION_ATTRIBUTE');
        
        if (targetName) {
          if (parameters) {
            const nestedElements = Object.keys(parameters).filter(item => item.startsWith(targetName) && item !== targetName);

            if (maxElementsAttribute?.value === 1) {
              const restElementName = Object.keys(parameters).find(item => item.startsWith(parentName) && item !== targetName);

              shape[targetName] = generateValidationRules(targetName, element, subSection, isFieldNotEmpty(parameters[restElementName]));
            } else if (hideUnderConditionAttributes.length) {
              const targetAttribute = hideUnderConditionAttributes.find(item => item.option === parameters[Object.values(item.elementReference).join('-')]);

              shape[targetName] = generateValidationRules(targetName, element, subSection, !!targetAttribute);
            } else {
              shape[targetName] = generateValidationRules(targetName, element, subSection);

              nestedElements?.forEach(item => {
                const element = findElementByName(template, item);

                shape[item] = generateValidationRules(item, element, subSection);
              })
            }
          } else {
            shape[targetName] = generateValidationRules(targetName, element, subSection)
          }
        }
      });
    });
  });

  return shape;
};

export const generateValidationRules = (
  name,
  element,
  parentElement,
  ignoreRequired = false
) => {
  const shape = {};

  if (parentElement.key) {
    shape[name] = Yup.object();
  } else {
    switch (element.type) {
      case "TEXT_INPUT":
      case "TEXT_AREA":
      case "DROP_DOWN":
      case "SWITCH_SELECTOR":
      case "GROUPED_SWITCH_SELECTOR":
      case "OPT_SLIDER_SELECTOR":
        shape[name] = Yup.string();
        break;
      case "INT_NUMBER_INPUT":
        shape[name] = Yup.number().nullable();
        break;
      case "MULTI_SELECTOR":
        shape[name] = Yup.array().of(Yup.string());
        break;
      case "FILE_UPLOAD":
      case "BOX_SWITCH_SELECTOR":
        shape[name] = Yup.object();
        break;
      case "URL_INPUT":
        shape[name] = Yup.string().matches(
          /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}\/?.*$/,
          "Enter a valid URL"
        );
        break;
      default:
        break;
    }
  }

  // set field is required
  if (!ignoreRequired) {
    if (
      !(
        parentElement.attributes.find((item) => item.type === "OPTIONAL_ATTRIBUTE") || 
        element.attributes.find((item) => item.type === "OPTIONAL_ATTRIBUTE")
      )
    ) {
      shape[name] = shape[name].required("This field is required");
    }
  }

  // handle other validation types
  element.attributes.forEach((attr) => {
    switch (attr.type) {
      case "MIN_LENGTH_ATTRIBUTE":
        shape[name] = shape[name].min(
          attr.value,
          `Min ${attr.value} ${attr.value > 1 ? "characters" : "character"}`
        );
        break;
      case "MAX_LENGTH_ATTRIBUTE":
        shape[name] = shape[name].max(
          attr.value,
          `Max ${attr.value} ${attr.value > 1 ? "characters" : "character"}`
        );
        break;
      case "MIN_VALUE_ATTRIBUTE":
        shape[name] = shape[name].min(attr.value, `Min ${attr.value}`);
        break;
      case "MAX_VALUE_ATTRIBUTE":
        shape[name] = shape[name].max(attr.value, `Max ${attr.value}`);
        break;
      case "MAX_OPTIONS_ATTRIBUTE":
        if (shape[name].type === "array") {
          shape[name] = shape[name].max(
            attr.value,
            `You can choose max ${attr.value} ${
              attr.value > 1 ? "options" : "option"
            }`
          );
        }
        break;
      default:
        break;
    }
  });

  return shape[name];
};

export const generateParameters = (template, values) => {
  const mainValues = { ...Object.fromEntries(Object.entries(values).sort(([keyA], [keyB]) => keyA.localeCompare(keyB))) };
  const separatedFields = template.sections.map(item => item.attributes.filter(item => item.type === 'SEPARATED_QUESTION_ATTRIBUTE')).flat();
  const separatedValues = {};

  separatedFields.forEach(item => {
    separatedValues[item.key] = mainValues[item.key];
    delete mainValues[item.key];
  });

  let result = {
    campaignTypeId: +template.id,
    sectionAnswerList: [],
    separatedSectionAnswers: {}
  };

  const getSection = (sections, id) => {
    const section = sections.find(section => section.id === id);
    if (section) return section;

    const newSection = { id, subSectionAnswerList: [] };
    return newSection;
  };

  const getSubSection = (subSections, id) => {
    const subSection = subSections.find(sub => sub.id === id);
    if (subSection) return subSection;

    const newSubSection = { id, elementAnswerList: [] };
    return newSubSection;
  };

  const getElement = (elements, id) => {
    const element = elements.find(el => el.id === id);
    if (element) return element;

    const newElement = { id, answers: [] };
    return newElement;
  };

  const addAnswer = (keys, value, parentOption = null, separatedKey = null) => {
    const [sectionId, subSectionId, elementId, ...rest] = keys.map(Number);

    let sectionList;
    if (separatedKey) {
      if (!result.separatedSectionAnswers[separatedKey]) {
        result.separatedSectionAnswers[separatedKey] = [];
      }
      sectionList = result.separatedSectionAnswers[separatedKey];
    } else {
      sectionList = result.sectionAnswerList;
    }

    let section = getSection(sectionList, sectionId);
    if (!sectionList.some(sec => sec.id === sectionId)) {
      sectionList.push(section);
    }

    let subSection = getSubSection(section.subSectionAnswerList, subSectionId);
    if (!section.subSectionAnswerList.some(sub => sub.id === subSectionId)) {
      section.subSectionAnswerList.push(subSection);
    }

    let element = getElement(subSection.elementAnswerList, elementId);
    if (!subSection.elementAnswerList.some(el => el.id === elementId)) {
      subSection.elementAnswerList.push(element);
    }

    if (rest.length) {
      const [nestedElementId, ...nestedKeys] = rest;
      addAnswer([sectionId, subSectionId, nestedElementId, ...nestedKeys], value, element.answers.length ? element.answers[0] : parentOption, separatedKey);
    } else if (isFieldNotEmpty(value)) {
      const newAnswer = parentOption 
        ? { PARENT: parentOption.OPTION || parentOption, OPTION: value } 
        : Array.isArray(value)
          ? value.map(item => ({
              OPTION: item
            }))
          : value;
      
      Array.isArray(value)
        ? newAnswer.forEach(item => {
            element.answers.push(item)
          })
        : element.answers.push(newAnswer)
    }
  };

  const traverseMainValues = (values, separatedKey = null) => {
    Object.entries(values).forEach(([key, value]) => {
      const keys = key.split('-');
      addAnswer(keys, value, null, separatedKey);
    });
  };

  const traverseSeparatedValues = (values) => {
    Object.entries(values).forEach(([key, value]) => {
      traverseMainValues(value, key);
    });
  };

  const removeEmptyAnswers = (sectionAnswerList) => {
    return sectionAnswerList
      .map(section => {
        const updatedSubSections = section.subSectionAnswerList
          .map(subSection => {
            const updatedElements = subSection.elementAnswerList
              .filter(element => element.answers.length > 0);
            return updatedElements.length > 0 ? { ...subSection, elementAnswerList: updatedElements } : null;
          })
          .filter(subSection => subSection !== null);

        return updatedSubSections.length > 0 ? { ...section, subSectionAnswerList: updatedSubSections } : null;
      })
      .filter(section => section !== null);
  };

  // Process both main and separated values
  traverseMainValues(mainValues);
  traverseSeparatedValues(separatedValues);

  return {
    ...result,
    sectionAnswerList: removeEmptyAnswers(result.sectionAnswerList),
    separatedSectionAnswers: Object.fromEntries(
      Object.entries(result.separatedSectionAnswers).map(([key, sections]) => [
        key,
        removeEmptyAnswers(sections)
      ])
    )
  };
};

export const findReferencedSubSection = (template, targetName) => {
  const result = [];

  template.sections.forEach((section) => {
    section.subSections.forEach((subSection) => {
      subSection.attributes.map((attribute) => {
        if (attribute.elementReference) {
          const refElemName = Object.values(attribute.elementReference).join(
            "-"
          );
  
          if (targetName === refElemName) {
            result.push({
              ...attribute,
              name: `${section.id}-${subSection.id}`
            });
          }
        }
      });
    });
  });

  return result;
};

export const findReferencedElement = (template, targetName) => {
  const result = [];

  template.sections.forEach((section) => {
    section.subSections.forEach((subSection) => {
      subSection.elements.forEach((element) => {
        element.attributes.forEach((attribute) => {
          if (attribute.elementReference) {
            const refElemName = Object.values(attribute.elementReference).join(
              "-"
            );

            if (targetName === refElemName) {
              result.push({
                ...attribute,
                name: `${section.id}-${subSection.id}-${element.id}`
              });
            }
          }
        });
      });
    });
  });

  return result;
};

export const findElementByName = (template, name) => {
  const ids = name.split('-');

  const traverse = (currentLevel, index) => {
    if (!Array.isArray(currentLevel)) return null;

    const id = ids[index];
    const match = currentLevel.find(item => item.id === parseInt(id));

    if (!match) return null;
    if (index === ids.length - 1) return match;

    if (match.subSections) return traverse(match.subSections, index + 1);
    if (match.elements) return traverse(match.elements, index + 1);
    if (match.groups) return traverse(match.groups.flatMap(group => group.options), index + 1);
    if (match.attributes) return traverse(match.attributes.flatMap(attr => attr.element ? [attr.element] : []), index + 1);

    return null;
  };

  return traverse(template.sections, 0);
};

export const findSubSectionByElementName = (template, name) => {
  const ids = name.split('-');

  const traverse = (currentLevel, index) => {
    if (!Array.isArray(currentLevel)) return null;

    const id = ids[index];
    const match = currentLevel.find(item => item.id === parseInt(id));

    if (!match) return null;

    // If the next level is the last ID in the path and it contains elements, we return the current match as the subsection
    if (index === ids.length - 2 && (match.subSections || match.elements || match.groups || match.attributes)) {
      return match;
    }

    // Continue traversal if further depth is available
    if (match.subSections) return traverse(match.subSections, index + 1);
    if (match.elements) return traverse(match.elements, index + 1);
    if (match.groups) return traverse(match.groups.flatMap(group => group.options), index + 1);
    if (match.attributes) return traverse(match.attributes.flatMap(attr => attr.element ? [attr.element] : []), index + 1);

    return null;
  };

  return traverse(template.sections, 0);
};

export const findElementsWithReference = (template, referenceName, targetTypes) => {
  const result = [];
  
  // Helper function to check if the elementReference matches the referenceName
  function matchesReference(elementReference, referenceName) {
    // Convert the referenceName into a format that can be compared
    const refString = `${elementReference.sectionId}-${elementReference.subsectionId}-${elementReference.elementId}`;
    
    // Check if the refString starts with the referenceName
    return refString.startsWith(referenceName) && (refString === referenceName || refString.startsWith(`${referenceName}-`));
  }

  // Function to remove duplicates from an array of objects
  function removeDuplicates(arr) {
    // Create a map to track unique elements
    const seen = new Map();
    return arr.filter(item => {
        // Create a unique key based on the criteria
        const key = `${item.type}-${item.id}`;
        // Check if the key is already in the map
        if (seen.has(key)) {
            return false;
        }
        // Otherwise, add the key to the map and include the item in the result
        seen.set(key, true);
        return true;
    });
  }
  
  template.sections.forEach(section => {
    section.subSections.forEach(subSection => {
      subSection.elements.forEach(element => {
        if (element.attributes.filter(item => item.elementReference && matchesReference(item.elementReference, referenceName) && targetTypes.includes(item.type))?.length) {
          result.push({
            ...element,
            name: `${section.id}-${subSection.id}-${element.id}`
          });
        }
      });
    });
  });

  return removeDuplicates(result);
}

export const generateInitialValue = (item) => {
  let initialValue = null;

  switch (item.type) {
    case 'string':
      initialValue = item.nullable ? null : ''
      break;
    case 'number':
      initialValue = item.nullable ? null : 0
      break;
    case 'array':
      initialValue = item.nullable ? null : []
      break;
    case 'object':
      initialValue = item.nullable ? null : {}
      break;
    default:
      break;
  }

  return initialValue;
}

export const isFieldNotEmpty = (value) => {
  return (
    value !== null &&
    value !== undefined &&
    ((typeof value === "number" && value > 0) ||
      (typeof value === "string" && value.trim().length > 0) ||
      (typeof value === "object" &&
        !Array.isArray(value) &&
        Object.keys(value).length > 0) ||
      (Array.isArray(value) && value.length > 0))
  );
};

export const handleMaxElements = (
  section,
  subSection,
  element,
  values,
  value,
  validationSchema,
  setFieldError,
  setValidationSchema,
  validateForm
) => {
  const targetName = `${section.id}-${subSection.id}-${element.id}`;
  const parentName = `${section.id}-${subSection.id}`;
  const maxElementsAttribute = subSection.attributes.find(item => item.type === 'MAX_ELEMENTS_ATTRIBUTE');
  
  if (maxElementsAttribute?.value === 1) {
    const restElement = subSection.elements.find(elem => elem.id !== element.id)
    const restElementName = Object.keys(values).find(item => item.startsWith(parentName) && item !== targetName);
    const newValidationSchema = { ...validationSchema };

    delete newValidationSchema[restElementName];

    newValidationSchema[restElementName] = generateValidationRules(restElementName, restElement, subSection, isFieldNotEmpty(value));
    setValidationSchema(newValidationSchema);

    // setTimeout uses to set the validateForm function into another task stack
    setTimeout(() => {
      validateForm();
    }, 500);
  }
}

export const handleHideSubSections = async (
  name,
  template,
  subSection,
  values,
  value,
  validationSchema,
  setFieldValue,
  setFieldError,
  setFieldTouched,
  setValidationSchema,
) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      const attributes = findReferencedSubSection(template, name).filter(item => item.type === 'HIDE_UNDER_CONDITION_ATTRIBUTE' && (item.option === value || item.option === values[name]));

      if (attributes.length) {
        const updatedValidationSchema = { ...validationSchema };
      
        attributes.forEach(item => {
          const subSectionElementKeys = Object.keys(updatedValidationSchema).filter(key => key.startsWith(item.name) && key !== item.name);
    
          subSectionElementKeys.forEach(key => {
            const subSection = findSubSectionByElementName(template, key);
    
            updatedValidationSchema[key] = generateValidationRules(key, findElementByName(template, key), subSection, item.option !== values[name]);
    
            setFieldValue(key, generateInitialValue({ ...validationSchema[key], nullable: !!validationSchema[key]?._nullable }));
            setFieldError(key, undefined);
            setFieldTouched(key, false);
          })
        })
    
        setValidationSchema(updatedValidationSchema);
      }

      resolve(true);
    }, 100);
  });
}

export const handleClearReferencedValue = async (
  name,
  template,
  validationSchema,
  setFieldValue,
  setFieldError
) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      const labelUnderConditionElement = findElementsWithReference(template, name, ['LABEL_UNDER_CONDITION_ATTRIBUTE'])[0];
  
      if (labelUnderConditionElement) {
        setFieldValue(labelUnderConditionElement.name, generateInitialValue({ ...validationSchema[labelUnderConditionElement.name], nullable: !!validationSchema[labelUnderConditionElement.name]?._nullable }), false);
        setFieldError(labelUnderConditionElement.name, undefined);
      }

      resolve(true);
    }, 100);
  });
}