import React from "react";
import {
  Box,
  Button,
  Typography,
  TextField,
  Select,
  MenuItem,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import DescribleCompanyPlanController, {
  Props,
} from "./DescribleCompanyPlanController";
import { ErrorMessage, Formik } from "formik";
import { styles } from "../utils/styles";
import Layout from "./Layout.web";
import CarouselDisplay from "../../../blocks/carouseldisplay/main/CarouselDisplay.web";
import { meunuProps } from "./About.web";
import * as Yup from "yup";

type FormikType = {
  describes: string;
  marketTarget: string;
  reach: number;
};

const phoneRegExp = /^\+?\d{7,14}$/;

const CompanySchema = Yup.object().shape({
  describes: Yup.string().required(
    "Please select best describes your company."
  ),
  marketTarget: Yup.string().required("Please select a target."),
  reach: Yup.string()
    .matches(phoneRegExp, "Please enter a valid reach number")
    .required("Please enter reach number."),
});

const defaultValue = { describes: "", marketTarget: "", reach: "" };

export class DescribleCompanyPlan extends DescribleCompanyPlanController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { classes } = this.props;
    return (
      <>
        <Typography className={classes.headerTitle}>
          {"Small Business Plan"}
        </Typography>
        <Box
          className={classes.layoutContainer}
          style={{ marginTop: "40px" }}
        >
          <Box className={classes.viewBox}>
            <Typography className={classes.createAccount}>
              {"Describe your company"}
            </Typography>
            <Typography className={classes.signingAs}>
              {"Could you also provide some details about your company?"}
            </Typography>

            <Formik
              validationSchema={CompanySchema}
              data-testid={"companyDetailsFormik"}
              initialValues={defaultValue}
              onSubmit={(value) => this.createCompanyAccount(value)}
            >
              {({ values, errors, handleChange, handleSubmit, touched }) => (
                <form onSubmit={handleSubmit} data-test-id={"AccountDetails"}>
                  <Box className={classes.inputWrapper}>
                    <Typography className={classes.title}>
                      {"What best describes your company?"}
                    </Typography>
                    <Select
                      displayEmpty
                      disableUnderline
                      value={values.describes}
                      onChange={handleChange}
                      className={`${classes.select} ${values.describes ? "" : classes.inactiveSelect1
                        }`}
                      data-test-id="seldesCompany"
                      MenuProps={meunuProps}
                      fullWidth
                      name="describes"
                      style={{
                        borderColor:
                          touched.describes && errors.describes
                            ? "#F87171"
                            : "",
                      }}
                    >
                      {this.state.companyDes.map((detail) => (
                        <MenuItem
                          key={detail.id}
                          className={classes.setmenuItem}
                          value={detail.id}
                        >
                          {detail.name}
                        </MenuItem>
                      ))}
                    </Select>
                    <ErrorMessage
                      name="describes"
                      component="div"
                      className={classes.errorResp}
                    />
                  </Box>
                  <Box className={classes.inputWrapper}>
                    <Typography className={classes.title}>
                      Which markets are you targeting?
                    </Typography>
                    <Select
                      value={values.marketTarget}
                      onChange={handleChange}
                      fullWidth
                      name="marketTarget"
                      MenuProps={meunuProps}
                      className={`${classes.select} ${values.marketTarget ? "" : classes.inactiveSelect1
                        }`}
                      data-test-id="targetMarket"
                      style={{
                        borderColor:
                          touched.marketTarget && errors.marketTarget
                            ? "#F87171"
                            : "",
                      }}
                      displayEmpty
                      disableUnderline
                    >
                      {this.state.marketList.map((detail) => (
                        <MenuItem
                          key={detail.id}
                          className={classes.setmenuItem}
                          value={detail.id}
                        >
                          {detail.name}
                        </MenuItem>
                      ))}
                    </Select>
                    <ErrorMessage
                      name="marketTarget"
                      component="div"
                      className={classes.errorResp}
                    />
                  </Box>
                  <Box className={classes.inputWrapper}>
                    <Typography className={classes.title}>
                      What's the best number to reach you at?
                    </Typography>
                    <TextField
                      id="reach"
                      variant="outlined"
                      value={values.reach}
                      data-test-id="txtInputWebsiteCompanyName"
                      name="reach"
                      fullWidth
                      onChange={handleChange}
                      className={classes.input1}
                      placeholder="Please enter valid phone number"
                      style={{
                        borderColor:
                          touched.reach && errors.reach ? "#F87171" : "",
                      }}
                    />
                    <ErrorMessage
                      name="reach"
                      component="div"
                      className={classes.errorResp}
                    />
                  </Box>
                  <Button
                    variant="contained"
                    fullWidth
                    type="submit"
                    className={classes.buttonWrapper}
                  >
                    Continue
                  </Button>
                </form>
              )}
            </Formik>
          </Box>
        </Box>
      </>
    );
  }
}

export default withStyles(styles)(DescribleCompanyPlan);
