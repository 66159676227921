import { api, apiCall, createApiCall } from "../api";
import { ApiResponse } from "../types";
import { CompanyIntelResponse, GetAttachmentsRequest, UploadAttachmentsRequest } from "../../interfaces/company-intel";

const companyAPI = api.injectEndpoints({
  endpoints: (builder) => ({
    getCompanyIntelAttachments: builder.query<Array<CompanyIntelResponse>, { informationType: string, fileName: string }>({
      query: (params) => ({
        url: "/v1/company_intel/attachments",
        method: "GET",
        params
      })
    }),
    deleteCompanyIntelData: builder.mutation<void, string>({
      query: (id) => ({
        url: `/v1/company_intel/attachments/${id}`,
        method: "DELETE"
      })
    }),
    uploadCompanyIntelInformation: builder.mutation<void, { file: any, type: string }>({
      query: ({ file, type }) => {
        const formData = new FormData();
        formData.append("fileAttachments", file);

        return {
          url: `/v1/company_intel/attachments?fileTypeDescription=${type}`,
          method: "POST",
          body: formData
        };
      }
    })
  }),
  overrideExisting: true
});

// Create a function to call the API without using the hook
export const getCompanyIntelAttachments = createApiCall<Array<CompanyIntelResponse>, GetAttachmentsRequest>(companyAPI.endpoints.getCompanyIntelAttachments);
export const deleteCompanyIntelData = createApiCall<void, string>(companyAPI.endpoints.deleteCompanyIntelData);
export const uploadCompanyIntelInformation = createApiCall<void, UploadAttachmentsRequest>(companyAPI.endpoints.uploadCompanyIntelInformation);
