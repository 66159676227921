import { IBlock } from "../../../framework/main/IBlock";
import { Message } from "../../../framework/main/Message";
import { BlockComponent } from "../../../framework/main/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/main/Messages/MessageEnum";
import { runEngine } from "../../../framework/main/RunEngine";
import {
  chipLabelsData,
  companyAchievementsInputListData,
  companyAchievementsServiceArrData,
  companyEvaluationInputListData,
  companyEvaluationServiceArrData,
  companyFactsInputListData,
  companyFactsServiceArrData,
  companyHeadQuarterServiceArrData,
  companyHeadquarterInputListData,
  companyValueInputListData,
  companyValueServiceArrayData,
  contributionInputListData,
  inputListData,
  mqlServiceArrData,
  mqlStatementInputListData,
  offeringsInputListData,
  offeringsServiceArrayData,
  primaryQuestionInputListData,
  primaryQuestionServiceArrData,
  primaryUtilityInputListData,
  primaryUtilityServiceArrData,
  serviceArray1Data,
  serviceArrayData,
  shouldHaveServiceArrayData,
  vissionStatementInputListData,
  companyTestimonialArrayData,
  companyTestimonialsInputData
} from "../utils/data";
import StorageProvider from "../../../framework/main/StorageProvider";
import { aboutCompanyOnboradingData } from "../../../helpers/onboarding";
import { getOnboardingData, saveOnboardingData, triggerAutofillOnboarding } from "../../../redux/services/onboarding";
import { observableService } from "../../../services/observable";
import { notify } from "../../../providers/notification";

export const initialValues: any = {
  whyExist: ["Democratise B2B success"],
  primaryFearQuestion1: "",
  primaryFearQuestion2: "",
  primaryFearQuestion3: "",
  offering1: "",
  description1: "",
  offering2: "",
  description2: "",
  offering3: "",
  description3: "",
  missionStatement: "",
  unique_selling_proposition: "",
  companyAchivements: [""],
  keyCompanyFacts: [""],
  companyEvalutionTime: [""],
  companyHeadquarters: [""],
  mqlStatement: [""],
  contributeToSocity: [""],
  primaryUtilityHook: [""],
  companyValue1: "",
  companyDescription1: "",
  companyValue2: "",
  companyDescription2: "",
  companyValue3: "",
  companyDescription3: "",
  visionStatement: [""],
  companyTestimonial: [""],
  formType: "company",
};

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  history: any;
  token?: string;
  autoFillEmitter?: any;
  setAutoSaveData?: any;
  autoSavedValues?: any;
  hideBackBtn?: any;
  formRef?: any;
  setFormRef?: any;
  closeTooltip?: (section: string, key: string) => void;
  tooltips?: any;
}

interface S {
  serviceArray: string[];
  serviceArray1: string[];
  shouldHaveServiceArray: string[];
  inputList: any;
  activeStep: any;
  prevCompletedStep: any;
  mustHaveExpanded: boolean;
  shouldHaveExpanded: boolean;
  goodToHaveExpanded: boolean;
  chipLabels: any;
  missionStatementValue: any;
  companyEvaluationInputList: any;
  companyEvaluationServiceArr: any;
  companyHeadquarterInputList: any;
  companyHeadQuarterServiceArr: any;
  primaryQuestionInputList: any;
  primaryQuestionServiceArr: any;
  companyAchievementsInputList: any;
  companyAchievementsServiceArr: any;
  companyFactsInputList: any;
  companyFactsServiceArr: any;
  vissionStateMentInputList: any;
  offeringsServiceArray: string[];
  offeringsInputList: {
    offeringPlaceholder: string;
    descriptionPlaceholder: string;
    offering: string;
    description: string;
    offeringTitle: string;
    descriptionTitle: string;
    charLength?: number;
    charLengthDesc?: number;
    wordsLength?: number;
    wordsLengthDesc?: number;
  }[];
  companyValueInputList: any;
  companyValueServiceArray: any;
  primaryUtilityServiceArr: any;
  mqlServiceArr: any;
  primaryUtilityInputList: any;
  mqlStatementInputList: any;
  contributionInputList: any;
  uniqueSellingValue: any;
  headerSubStep: any;
  headerTime: any;
  headerType: any;
  headerPrimaryHeading: any;
  headerSecondaryHeading: any;
  companyTestimonialArrayData: any;
  alreadyFilledFormValues: any;
  loading: boolean
  clearAllInfoDialog: boolean;
  skipModalOpen: boolean;
  formValues?: any;
  setAutoSaveData?: any;
  formRef?: any;
  showUploadDocumentsModal?: boolean;
}

interface SS {
  id: any;
}

export default class AboutTheCompanyController extends BlockComponent<
  Props,
  S,
  SS
> {
  initDataMessageId: string = "";
  dataFromAPIMsgID: string = "";
  completedSteps: any;
  token: string;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];
    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.state = {
      mustHaveExpanded: true,
      shouldHaveExpanded: false,
      goodToHaveExpanded: false,
      activeStep: 1,
      prevCompletedStep: null,
      missionStatementValue: "",
      uniqueSellingValue: "",
      offeringsServiceArray: offeringsServiceArrayData,
      offeringsInputList: offeringsInputListData,
      companyValueServiceArray: companyValueServiceArrayData,
      companyValueInputList: companyValueInputListData,
      vissionStateMentInputList: vissionStatementInputListData,
      companyEvaluationInputList: companyEvaluationInputListData,
      companyEvaluationServiceArr: companyEvaluationServiceArrData,
      companyHeadquarterInputList: companyHeadquarterInputListData,
      companyHeadQuarterServiceArr: companyHeadQuarterServiceArrData,
      primaryUtilityServiceArr: primaryUtilityServiceArrData,
      mqlServiceArr: mqlServiceArrData,
      primaryUtilityInputList: primaryUtilityInputListData,
      mqlStatementInputList: mqlStatementInputListData,
      contributionInputList: contributionInputListData,
      companyAchievementsInputList: companyAchievementsInputListData,
      companyAchievementsServiceArr: companyAchievementsServiceArrData,
      companyFactsInputList: companyFactsInputListData,
      companyFactsServiceArr: companyFactsServiceArrData,
      primaryQuestionInputList: primaryQuestionInputListData,
      primaryQuestionServiceArr: primaryQuestionServiceArrData,
      shouldHaveServiceArray: shouldHaveServiceArrayData,
      companyTestimonialArrayData: companyTestimonialArrayData,
      serviceArray: serviceArrayData,
      serviceArray1: serviceArray1Data,
      inputList: companyTestimonialsInputData,
      chipLabels: chipLabelsData,
      headerSubStep: "1",
      headerTime: "5",
      headerType: "gold",
      headerPrimaryHeading: "Company",
      loading: false,
      headerSecondaryHeading:
        "Let's begin by understanding the purpose behind the business.",
      alreadyFilledFormValues: {},
      clearAllInfoDialog: false,
      skipModalOpen: false,
      setAutoSaveData: null,
      formRef: null,
      showUploadDocumentsModal: false
    };
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (responseJson) {
        switch (apiRequestCallId) {
          case this.dataFromAPIMsgID:
            this.handleReplaceDataFromAPI(responseJson)
            break;
        }
      }
    }

    this.setState({
      loading: false
    });
  }

  async componentDidMount() {
    super.componentDidMount();
    this.props.autoFillEmitter(this.fetchDataFromAPI);

    const token = await StorageProvider.getItem("user-token");
    this.token = token;

    this.setQueryParam('partOfStep', 'mustHaveExpanded');

    this.setState({
      mustHaveExpanded: true,
      shouldHaveExpanded: false,
      goodToHaveExpanded: false,
      headerSubStep: 1
    });

    this.fetchInitData();
    this.scrollToTop();
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS): void {
    if (
      (prevState.mustHaveExpanded !== this.state.mustHaveExpanded) ||
      (prevState.shouldHaveExpanded !== this.state.shouldHaveExpanded) ||
      (prevState.goodToHaveExpanded !== this.state.goodToHaveExpanded)
    ) {
      const {
        mustHaveExpanded,
        shouldHaveExpanded,
        goodToHaveExpanded
      } = this.state;
      const trueKey = Object
        .keys({
          mustHaveExpanded,
          shouldHaveExpanded,
          goodToHaveExpanded
        })
        .find(key => this.state[key] === true);

      this.setQueryParam('partOfStep', trueKey || 'goodToHaveExpanded');
    }
  }

  async componentWillUnmount() {
    this.setState({
      alreadyFilledFormValues: {}
    })
  }

  scrollToTop = () => {
    const scrollContainer = document.getElementById('scrollContainer');

    if (scrollContainer) {
      scrollContainer.scrollTo({
        top: 1,
        behavior: "smooth"
      });
    }
  }

  setQueryParam = (key, value) => {
    const url = new URL(window.location.href);
    url.searchParams.set(key, value);
    window.history.replaceState({}, '', url);
  };

  openSkipConfirmationDialog = () => {
    this.setState({
      skipModalOpen: true
    })
  };

  closeSkipConfirmationDialog = () => {
    this.setState({
      skipModalOpen: false
    })
  };

  async redirectToPage(path: string) {
    await StorageProvider.removeItem('isEdit');

    this.props.navigation.navigate(path);
  };

  checkValidVal = (input: any, position: number, key: string) => {
    try {
      return input[position][key];
    } catch (error) {
      return ''
    }
  };

  handleReplaceDataFromAPI = (resJson: any) => {
    this.setState({
      loading: false
    });

    if (!resJson.errors && !resJson.error) {
      const mustHaveSection = {
        missionStatement: resJson.MissionStatement,
        unique_selling_proposition: resJson.UniqueSellingProposition,
        primaryFearQuestion1: this.checkValidVal(resJson.PrimaryFearQuestions, 0, 'Question'),
        primaryFearQuestion2: this.checkValidVal(resJson.PrimaryFearQuestions, 1, 'Question'),
        primaryFearQuestion3: this.checkValidVal(resJson.PrimaryFearQuestions, 2, 'Question'),
        offering1: this.checkValidVal(resJson.ProblemStatements, 0, 'Title_1'),
        description1: this.checkValidVal(resJson.ProblemStatements, 0, 'Description_1'),
        offering2: this.checkValidVal(resJson.ProblemStatements, 1, 'Title_2'),
        description2: this.checkValidVal(resJson.ProblemStatements, 1, 'Description_2'),
        offering3: this.checkValidVal(resJson.ProblemStatements, 2, 'Title_3'),
        description3: this.checkValidVal(resJson.ProblemStatements, 2, 'Description_3'),
      }

      const shouldHaveSection = {
        companyEvalutionTime: [resJson.CompanyEvolutionTimeline],
        companyHeadquarters: [resJson.Headquarters],
        companyAchivements: resJson.CompanyAchievements,
        companyTestimonial: resJson.CompanyTestimonials,
        keyCompanyFacts: resJson.KeyCompanyFacts
      }

      const goodHaveSection = {
        visionStatement: [resJson.VisionStatement],
        companyValue1: this.checkValidVal(resJson.CompanyValues, 0, 'Title'),
        companyValue2: this.checkValidVal(resJson.CompanyValues, 1, 'Title'),
        companyValue3: this.checkValidVal(resJson.CompanyValues, 2, 'Title'),
        companyDescription1: this.checkValidVal(resJson.CompanyValues, 0, 'Body'),
        companyDescription2: this.checkValidVal(resJson.CompanyValues, 1, 'Body'),
        companyDescription3: this.checkValidVal(resJson.CompanyValues, 2, 'Body'),
        primaryUtilityHook: [resJson.PrimaryUtilityHook],
        mqlStatement: [resJson.MQLStatement],
        contributeToSocity: [resJson.ContributionToSociety],
      }

      this.setState({
        chipLabels: this.state.chipLabels || chipLabelsData,
        alreadyFilledFormValues: {
          ...initialValues,
          ...this.state.alreadyFilledFormValues,
          ...mustHaveSection,
          ...shouldHaveSection,
          ...goodHaveSection,
        },
        shouldHaveExpanded: true,
        goodToHaveExpanded: false,
        mustHaveExpanded: false
      })
    } else {
      this.parseApiErrorResponse(resJson);
    }
  }

  backButton = async () => {
    const isEdit = await StorageProvider.getItem('isEdit')
    const message: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      !!isEdit ? "LandingPage" : "BulkUploading"
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }

  fetchDataFromAPI = async () => {
    notify.info(`We are preparing AI. It could take some time. Don't refresh and don't leave the page.`);

    this.setState({
      mustHaveExpanded: false,
      shouldHaveExpanded: false,
      goodToHaveExpanded: false,
      headerSubStep: 1
    });

    this.setState({
      loading: true
    })

    const { data, error } = await triggerAutofillOnboarding({
      types: [
        'AC_COMPANY'
      ],
      sections: [
        'MUST_HAVE',
        'SHOULD_HAVE',
        'GOOD_TO_HAVE'
      ]
    });

    if (!data || error) {
      if (error?.status === 402) {
        observableService.sendEvent('Show top up modal for ONBOARDING');
      } else if (error?.status === 422) {
        notify.error(`Please, upload the documents or provide website URL`);

        this.setState({
          showUploadDocumentsModal: true
        })
      } else {
        notify.error(`Something went wrong. Please try again.`);
      }

      this.setState({
        mustHaveExpanded: true,
        shouldHaveExpanded: false,
        goodToHaveExpanded: false,
        headerSubStep: 1
      });
    } else if (Array.isArray(data)) {
      observableService.sendEvent('Increase usage amount');

      notify.error(`About company auto-filled successfully`);

      const alreadyFilledFormValues = {
        whyExist: [],
        offering1: "",
        description1: "",
        offering2: "",
        description2: "",
        offering3: "",
        description3: "",
        primaryFearQuestion1: "",
        primaryFearQuestion2: "",
        primaryFearQuestion3: "",
        missionStatement: "",
        unique_selling_proposition: "",
        visionStatement: [""],
        companyHeadquarters: [""],
        keyCompanyFacts: [""],
        companyEvalutionTime: [""],
        companyValue1: "",
        companyDescription1: "",
        companyValue2: "",
        companyDescription2: "",
        companyValue3: "",
        companyDescription3: "",
        companyTestimonial: [""],
        primaryUtilityHook: [""],
        companyAchivements: [""],
        mqlStatement: [""],
        contributeToSocity: [""],
      };

      data.forEach(item => {
        if (item.parameter_name === 'why_semantic_keywords') {
          alreadyFilledFormValues.whyExist = item.value || []
        }
        if (item.parameter_name === 'company_evolution_timeline') {
          alreadyFilledFormValues.companyEvalutionTime = item.value.length ? [`${item.value}`] : [""]
        }
        if (item.parameter_name === 'problem_statement') {
          item.value.forEach((item, index) => {
            alreadyFilledFormValues[`offering${index + 1}`] = item.title || item.name
            alreadyFilledFormValues[`description${index + 1}`] = item.description
          })
        }
        if (item.parameter_name === 'mission_statement') {
          alreadyFilledFormValues.missionStatement = item.value.length ? item.value : ''
        }
        if (item.parameter_name === 'unique_selling_proposition') {
          alreadyFilledFormValues.unique_selling_proposition = item.value.length ? item.value : ''
        }
        if (item.parameter_name === 'vision_statement') {
          alreadyFilledFormValues.visionStatement = item.value.length ? [`${item.value}`] : [""]
        }
        if (item.parameter_name === 'primary_fear_question') {
          item.value.forEach((item, index) => {
            alreadyFilledFormValues[`primaryFearQuestion${index + 1}`] = item
          })
        }
        if (item.parameter_name === 'company_headquarters') {
          alreadyFilledFormValues.companyHeadquarters = Array.isArray(item.value) ? item.value : [`${item.value}`]
        }
        if (item.parameter_name === 'key_company_fact') {
          alreadyFilledFormValues.keyCompanyFacts = item.value.length ? item.value : [""]
        }
        if (item.parameter_name === 'company_value') {
          item.value.forEach((item, index) => {
            alreadyFilledFormValues[`companyValue${index + 1}`] = item.title || item.name
            alreadyFilledFormValues[`companyDescription${index + 1}`] = item.description
          })
        }
        if (item.parameter_name === 'company_testimonial') {
          alreadyFilledFormValues.companyTestimonial = item.value.length
            ? item.value
            : [""]
        }
        if (item.parameter_name === 'primary_utility_hook') {
          alreadyFilledFormValues.primaryUtilityHook = Array.isArray(item.value) ? item.value : [`${item.value}`]
        }
        if (item.parameter_name === 'company_achievement') {
          alreadyFilledFormValues.companyAchivements = item.value.length ? item.value : [""]
        }
        if (item.parameter_name === 'mql_statement') {
          alreadyFilledFormValues.mqlStatement = Array.isArray(item.value) ? item.value : [`${item.value}`]
        }
        if (item.parameter_name === 'contribution_to_society') {
          alreadyFilledFormValues.contributeToSocity = Array.isArray(item.value) ? item.value : [`${item.value}`]
        }
      })

      this.state.setAutoSaveData('company', alreadyFilledFormValues);
      this.state.formRef.setValues(alreadyFilledFormValues);
      this.state.formRef.validateForm();

      // if (!Object.keys(this.state.formRef?.errors)?.length) {
      //   this.saveAndContinue(alreadyFilledFormValues, true, true);
      // }

      this.setState({
        mustHaveExpanded: true,
        shouldHaveExpanded: false,
        goodToHaveExpanded: false,
        headerSubStep: 1
      });

      // this.setState({
      //   alreadyFilledFormValues: alreadyFilledFormValues,
      // })
    }

    this.setState({
      loading: false
    })
  }

  fetchInitData = async () => {
    this.setState({
      loading: true
    })

    const { data } = await getOnboardingData();

    if (data && Array.isArray(data) && data.length) {
      const {
        mustHaveSection,
        shouldHaveSection,
        goodHaveSection,
      } = aboutCompanyOnboradingData(data);

      const alreadyFilledFormValues = {
        ...initialValues,
        ...mustHaveSection,
        ...shouldHaveSection,
        ...goodHaveSection
      };

      this.state.formRef?.setValues(alreadyFilledFormValues);
      this.state.formRef?.validateForm();

      this.setState({
        chipLabels: mustHaveSection.whyExist || chipLabelsData,
        alreadyFilledFormValues,
      })
    } else {
      this.setState({
        alreadyFilledFormValues: {}
      })
    }

    this.setState({
      loading: false
    })
  }

  handleChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
    index: number
  ) => {
    const { name, value } = event.target;
    const list: any = [...this.state.inputList];
    list[index][name] = value;
    this.setState({ inputList: list });
  };

  handleCommonObjectChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number,
    listName: keyof S, // Use keyof to specify the list name
    propertyName: string
  ) => {
    const { value } = event.target;
    this.setState((prevState): any => {
      const updatedList = [...prevState[listName]];
      updatedList[index][propertyName] = value;
      return { [listName]: updatedList };
    });
  };
  handleExpand = () => {
    this.setState({
      mustHaveExpanded: !this.state.mustHaveExpanded,
      // shouldHaveExpanded: false,
      // goodToHaveExpanded: false,
      headerSubStep: 1
    });
  };
  handleExpand1 = () => {
    this.setState({
      // mustHaveExpanded: false,
      shouldHaveExpanded: !this.state.shouldHaveExpanded,
      // goodToHaveExpanded: false,
      headerSubStep: 2
    });
  };
  handleExpand2 = () => {
    this.setState({
      // mustHaveExpanded: false,
      // shouldHaveExpanded: false,
      goodToHaveExpanded: !this.state.goodToHaveExpanded,
      headerSubStep: 3
    });
  };
  deleteChipByName = (nameToDelete: any) => {
    this.setState((prevState) => ({
      chipLabels: prevState.chipLabels.filter(
        (label: any) => label !== nameToDelete
      ),
    }));
  };
  addChipLabels = (value: string) => {
    const { chipLabels } = this.state;
    if (value.trim() !== "") {
      // Create a new array with the new item added to the end
      const updatedItems = [...chipLabels, value];
      this.setState({
        chipLabels: updatedItems,
      });
    }
  };
  handleAddItem = (value: any) => {
    const { inputList } = this.state;
    if (value.placeholder.trim() !== "") {
      // Create a new array with the new item added to the end
      const updatedInputList = [...inputList, value];
      this.setState({
        inputList: updatedInputList,
      });
    }
  };
  handleAddObjectItem = () => {
    const { inputList } = this.state;

    // Create a new item and add it to the inputList
    const newItem = {
      label: "Testimonial",
      placeholder: " Michael Kert | Chairman | SIF Ventures | Venture Capital | SaaS Pebbles Ai has been invaluable and indispensable in our efforts to build one of the world's first early-stage Web3 VC. He has gone above and beyond and displayed a great sense of support and loyalty toward our team.",
      value: "",
      name: `Testimonial${inputList.length + 1}`,
      charLength: 30,
    };

    this.setState({
      inputList: [...inputList, newItem],
    });
  };

  handleAddObjectItemCompanyAcheivement = () => {
    const { companyAchievementsInputList } = this.state;

    // Create a new item and add it to the inputList
    const newItem = {
      label: "Achievement",
      placeholder:
        "Published in Europe’s marketing bestseller book by Oxford University Press in 2022",
      value: "",
      name: `achievement${companyAchievementsInputList.length + 1}`,
      charLength: 30,
    };

    this.setState({
      companyAchievementsInputList: [...companyAchievementsInputList, newItem],
    });
  };

  handleAddObjectItemCompanyFacts = () => {
    const { companyFactsInputList } = this.state;

    // Create a new item and add it to the inputList
    const newItem = {
      label: "Fact",
      placeholder: "12 industries of combined knowledge",
      value: "",
      name: `fact${companyFactsInputList.length + 1}`,
      charLength: 50,
    };

    this.setState({
      companyFactsInputList: [...companyFactsInputList, newItem],
    });
  };

  clearAllInformation = async (values: any) => {
    this.handleExpandSection('none');

    await this.mustHaveOnSubmit(values, true);
    await this.shouldHaveOnSubmit(values, true);
    await this.goodToHaveOnSubmit(values, true);

    this.setState({ alreadyFilledFormValues: {} });

    this.setState({
      loading: true
    });

    setTimeout(() => {
      this.handleExpandSection('mustHaveExpanded');

      notify.error(`Data has been cleared successfully`);

      this.setState({
        loading: false
      });
    }, 2000);
  };

  saveAndContinue = async (values: any, shadowSave?: boolean, entireSaving?: boolean) => {
    if (entireSaving) {
      this.setState({
        loading: true
      })

      await this.mustHaveOnSubmit(values, shadowSave);
      await this.shouldHaveOnSubmit(values, shadowSave);
      await this.goodToHaveOnSubmit(values, shadowSave);

      this.setState({
        loading: false
      })
    } else {
      if (this.state.mustHaveExpanded) {
        this.mustHaveOnSubmit(values, shadowSave);
      } else if (this.state.shouldHaveExpanded) {
        this.shouldHaveOnSubmit(values, shadowSave);
      } else {
        this.goodToHaveOnSubmit(values, shadowSave);
      }
    }
  };

  handleExpandSection = (targetSection) => {
    this.setState({
      mustHaveExpanded: targetSection === 'mustHaveExpanded',
      shouldHaveExpanded: targetSection === 'shouldHaveExpanded',
      goodToHaveExpanded: targetSection === 'goodToHaveExpanded',
    })
  }

  async mustHaveOnSubmit(formValues: any, shadowSave?: boolean) {
    this.setState({
      loading: !shadowSave
    });

    const body = {
      objectType: "COMPANY",
      detailsType: "MUST",
      attributes: {
        why_semantic_keywords: formValues.whyExist,
        mission_statement: formValues.missionStatement,
        unique_selling_proposition: formValues.unique_selling_proposition,
        primary_fear_question: [
          formValues.primaryFearQuestion1,
          formValues.primaryFearQuestion2,
          formValues.primaryFearQuestion3
        ],
        problem_statement: [
          {
            title: formValues.offering1,
            description: formValues.description1,
          },
          {
            title: formValues.offering2,
            description: formValues.description2,
          },
          {
            title: formValues.offering3,
            description: formValues.description3,
          },
        ],
      }
    };

    const { error } = await saveOnboardingData(body);

    if (!error) {
      if (!shadowSave) {
        this.handleExpandSection('shouldHaveExpanded');
      }
    } else {
      if (!shadowSave) {
        notify.error(`Something went wrong. Please try again.`);
      }
    }

    this.setState({
      loading: false
    });
  }

  async shouldHaveOnSubmit(formValues: any, shadowSave?: boolean) {
    this.setState({
      loading: !shadowSave
    });

    const body = {
      objectType: "COMPANY",
      detailsType: "SHOULD",
      attributes: {
        key_company_fact: formValues.keyCompanyFacts,
        company_evolution_timeline: formValues.companyEvalutionTime[0],
        company_headquarters: formValues.companyHeadquarters[0],
        company_testimonial: formValues.companyTestimonial,
        company_achievement: formValues.companyAchivements,
      }
    };

    const { error } = await saveOnboardingData(body);

    if (!error) {
      if (!shadowSave) {
        this.handleExpandSection('goodToHaveExpanded');
      }
    } else {
      if (!shadowSave) {
        notify.error(`Something went wrong. Please try again.`);
      }
    }

    this.setState({
      loading: false
    });
  }

  async goodToHaveOnSubmit(formValues: any, shadowSave?: boolean) {
    this.setState({
      loading: !shadowSave
    });

    const body = {
      objectType: "COMPANY",
      detailsType: "GOOD",
      attributes: {
        vision_statement: formValues.visionStatement[0],
        company_value: [
          { title: formValues.companyValue1, description: formValues.companyDescription1 },
          { title: formValues.companyValue2, description: formValues.companyDescription2 },
          { title: formValues.companyValue3, description: formValues.companyDescription3 },
        ],
        primary_utility_hook: formValues.primaryUtilityHook[0],
        mql_statement: formValues.mqlStatement[0],
        contribution_to_society: formValues.contributeToSocity[0],
      }
    };

    const { error } = await saveOnboardingData(body);

    if (!error) {
      if (!shadowSave) {
        this.props.navigation.navigate('/onboarding/about-your-approach');

        notify.success(`Thank you for completing The Company section! Now, let's add some information about Your Approach.`);
      }
    } else {
      if (!shadowSave) {
        notify.error(`Something went wrong. Please try again.`);
      }
    }

    this.setState({
      loading: false
    });
  }

  checkValidation = (touch: boolean, error: any) => {
    return !!touch && !!error ? error : ''
  }

  openClearAllInfoDilog = () => {
    this.setState({
      clearAllInfoDialog: true
    })
  }

  closeClearAllInfoDilog = () => {
    this.setState({
      clearAllInfoDialog: false
    })
  }

  redirectToStep = (url) => {
    this.props.navigation.navigate(url);
  }

  handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  }
}
