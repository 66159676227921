import React, { useEffect, useRef, useState } from "react";
import { Box, Button, CircularProgress, IconButton, Typography } from "@material-ui/core";
import TextField from "../../../../components/main/CustomFields/TextField";
import { generateReply, getReplyTemplate } from "../../../../redux/services/tactical-outreach";
import * as Yup from 'yup';
import TooltipModal from "../../../TacticalOutreachBuilder/components/TooltipModal";
import { Form, Formik } from "formik";
import { generateInitialValue, generateParameters, generateYupSchema } from "../../../TacticalOutreachBuilder/helpers";
import { ReactComponent as CopyIcon } from '../../../../assets/icons/copy_email_content.svg';
import { ReactComponent as GreenCheckmark } from '../../../../assets/icons/green_checkmark_mini.svg';
import _ from 'lodash';
import { observableService } from "../../../../services/observable";
import SubSectionElement from "../../../TacticalOutreachBuilder/components/SubSectionElement";
import { Editor } from 'react-draft-wysiwyg';
import { convertHTMLToState, convertMarkdownToHtml, convertStateToHTML, copyHtmlToClipboard } from "../../helpers";
import { ReactComponent as SendMessageIcon } from '../../../../assets/icons/send_message_icon.svg';
import { notify } from "../../../../providers/notification";

import { useStyles } from "./styles";

interface Props {
  email: any;
  containerRef: any;
}

const ReplyGenerator: React.FC<Props> = ({
  email,
  containerRef
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [template, setTemplate] = useState<any>(null);
  const [reply, setReply] = useState<string>('');
  const [tailoredReply, setTailoredReply] = useState<string | null>(null);
  const [validationSchema, setValidationSchema] = useState<any>(null);
  const [tooltipMessage, setTooltipMessage] = useState<string | null>(null);
  const [editorState, setEditorState] = useState<any>(null);

  const classes = useStyles();
  const formRef = useRef<any>(null);

  useEffect(() => {
    setTailoredReply(null);
    setReply('');
    setTemplate(null);
    setValidationSchema(null);

    scrollTo('top');
  }, [email]);

  const handleGetReplyTemplate = async () => {
    setLoading(true);

    scrollTo('bottom');

    const { data, error } = await getReplyTemplate({ body: { reply: reply }, emailId: email.id });

    if (data && !error) {
      const shape = generateYupSchema(data, null);
  
      setValidationSchema(shape);
      setTemplate(data);
      setLoading(false);

      scrollTo('bottom');

      const intervalId = setInterval(() => {
        if (formRef?.current) {
          clearInterval(intervalId);

          data.sections.forEach(section => {
            section.subSections.forEach(subSection => {
              subSection.elements.forEach(element => {
                const definedValueAttribute = element.attributes.find(item => item.type === 'DEFINED_ANSWER_ATTRIBUTE');

                if (definedValueAttribute) {
                  formRef.current.setFieldValue(`${section.id}-${subSection.id}-${element.id}`, definedValueAttribute.answer);
                  formRef.current.setFieldError(`${section.id}-${subSection.id}-${element.id}`, undefined);
                  formRef.current.setFieldTouched(`${section.id}-${subSection.id}-${element.id}`, true);
                }
              })
            })
          })

          setLoading(false);
        }
      }, 1000);
    } else {
      setLoading(false);
      
      setTailoredReply(null);
      setTemplate(null);
      setValidationSchema(null);
    }
  };

  const handleGenerateReply = async (values) => {
    setTailoredReply('');
    setLoading(true);

    const { sectionAnswerList } = generateParameters(
      { 
        ...template, 
        id: email.campaignId 
      }, 
      values
    );

    const { data, error } = await generateReply({
      body: {
        reply: reply,
        arguments: {
          sectionAnswerList
        }
      },
      emailId: email.id
    });

    if (data && !error) {
      const content = convertMarkdownToHtml(data.content);

      setEditorState(
        convertHTMLToState(content)
      );
      setTailoredReply(data.content);

      observableService.sendEvent('Increase usage amount');

      scrollTo('bottom');
    } else {
      handleClearReply();
      
      notify.error('Failed to generate reply');
    }

    setLoading(false);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };

  const scrollTo = (directionY) => {
    if (containerRef) {
      setTimeout(() => {
        containerRef.current.scrollTo({
          top: directionY === 'bottom' ? containerRef.current.scrollHeight - 1 : 1,
          behavior: 'smooth', // Smooth scrolling
        });
      }, 100);
    }
  };

  const getInitialValues = () => {
    const initialValues = {};

    Object.entries(Yup.object().shape(validationSchema).describe().fields).forEach(([key, value]) => {
      initialValues[key] = generateInitialValue({ ...value, nullable: !!validationSchema[key]?._nullable })
    });

    return initialValues;
  }

  const handleClearReply = () => {
    setTailoredReply(null);
    setReply('');
    setTemplate(null);
    setValidationSchema(null);
  }

  const handleCopyReply = async () => {
    const html = convertStateToHTML(editorState);

    const isSuccessully = await copyHtmlToClipboard(html);

    isSuccessully
      ? notify.info('Copied to clipboard')
      : notify.error('Failed to copy')
  }

  return (
    <>
      <Box
        style={{
          width: '100%',
          maxWidth: '1080px',
          minWidth: '650px',
          padding: '0px 20px 60px 20px',
          margin: '0 auto',
        }}
      >
        <Box
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            gap: '20px',
            background: '#F8FAFC',
            borderRadius: '8px',
            padding: '12px'
          }}
        >
          {tailoredReply
            ? <Box 
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '8px'
                }}
              >
                <Typography style={{ display: 'flex', alignItems: 'center', gap: '8px', fontSize: '16px', fontWeight: '600', lineHeight: '19px' }}>
                  <GreenCheckmark /> Your reply is ready!
                </Typography>
                <Typography className={classes.message}>
                  <Editor 
                    editorState={editorState}
                    readOnly={true}
                    toolbarHidden={true}
                    wrapperClassName={`wrapper-class ${classes.editorWrapper}`}
                    toolbarClassName={`toolbar-class ${classes.editorToolbar}`}
                    editorClassName={`editor-class ${classes.editorTextarea}`}
                  />
                </Typography>
                <Box 
                  style={{
                    display: 'flex',
                    gap: '8px',
                    marginTop: '12px'
                  }}
                >
                  <Button
                    color={'secondary'}
                    variant={'outlined'}
                    startIcon={<CopyIcon />}
                    onClick={() => handleCopyReply()}
                  >
                    Copy
                  </Button>
                  <Button
                    color={'secondary'}
                    variant={'outlined'}
                    onClick={() => handleClearReply()}
                  >
                    Clear
                  </Button>
                </Box>
              </Box>
            : <>
                {typeof tailoredReply !== 'string' && (
                  <TextField
                    value={reply}
                    type={'textarea'}
                    label={'Incoming reply'}
                    placeholder={'Paste the response from your lead to generate a tailored reply...'}
                    disabled={loading}
                    endAdornment={!!reply.trim().length && !loading && (
                      <IconButton
                        onClick={() => handleGetReplyTemplate()}
                      >
                        <SendMessageIcon />
                      </IconButton>
                    )}
                    onChange={(event) => {
                      setTemplate(null);
                      setValidationSchema(null);

                      setReply(event.target.value || '');
                    }}
                    onKeyPress={(event) => {
                      if ((event.code.toLowerCase() === 'enter' || event.key.toLowerCase() === 'enter') && !event.shiftKey) {
                        if (!!reply.trim().length) {
                          handleGetReplyTemplate()
                        }
                      }
                    }}
                  />
                )}

                {loading
                  ? <CircularProgress
                      color="inherit"
                      style={{
                        width: '25px',
                        height: '25px',
                        margin: '40px auto'
                      }}
                    />
                  : template && validationSchema && (
                      <Formik
                        innerRef={formRef}
                        initialValues={getInitialValues()}
                        validationSchema={Yup.object().shape(validationSchema)}
                        validateOnChange={true}
                        validateOnBlur={true}
                        isInitialValid={false}
                        onSubmit={(values: any) => handleGenerateReply(values)}
                      >
                        {({
                          values,
                          errors,
                          touched,
                          submitForm,
                          validateField,
                          validateForm,
                          resetForm,
                          setValues,
                          setErrors,
                          setFieldValue,
                          setFieldError,
                          setFieldTouched
                        }) => (
                          <Form
                            translate={undefined}
                            onKeyDown={handleKeyDown}
                          >
                            <Box
                              style={{
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '20px'
                              }}
                            >
                              {values && errors && touched && (
                                template.sections.map((section, sectionIndex) => (
                                  section.subSections.map((subSection, subSectionIndex) => (
                                    subSection.elements.filter(item => item.attributes.find(item => item.type !== 'DEFINED_ANSWER_ATTRIBUTE')).length >= 1 && (
                                      <Box key={`${sectionIndex}-${subSectionIndex}`}>
                                        <Typography style={{ fontSize: '16px', fontWeight: '600', lineHeight: '19px', marginBottom: '8px' }}>
                                          {subSection.title}
                                          &nbsp;
                                          <Typography style={{ display: 'inline', fontSize: '16px', fontWeight: '600', lineHeight: '19px', color: 'red' }}>
                                            *
                                          </Typography>
                                        </Typography>
                                        {subSection.elements
                                          .map(elem => (
                                            <SubSectionElement
                                              item={elem}
                                              name={`${section.id}-${subSection.id}-${elem.id}`}
                                              values={values}
                                              errors={errors}
                                              touched={touched}
                                              validationSchema={validationSchema}
                                              template={template}
                                              uukey={null}
                                              isAllowToEdit={true}
                                              setValues={setValues}
                                              setErrors={setErrors}
                                              setFieldValue={(field: string, value: any, shouldValidate?: boolean) => {
                                                setFieldError(field, undefined);
                                                setFieldValue(field, value, !!shouldValidate);
                                              }}
                                              setFieldError={setFieldError}
                                              setFieldTouched={setFieldTouched}
                                              setValidationSchema={setValidationSchema}
                                              setTooltipMessage={setTooltipMessage}
                                            />
                                          ))
                                        }
                                      </Box>
                                    )
                                  ))
                                ))
                              )}
                              <Button
                                type={'submit'}
                                color={'primary'}
                                variant={'contained'}
                              >
                                Generate reply
                              </Button>
                            </Box>
                          </Form>
                        )}
                      </Formik>
                    )
                }
              </>
          }
        </Box>
      </Box>

      <TooltipModal
        open={!!tooltipMessage?.length}
        message={tooltipMessage}
        onClose={() => setTooltipMessage(null)}
      />
    </>
  );
};

export default ReplyGenerator;
