import React from "react";
import {
  Box,
  Button,
  Typography,
  Select,
  MenuItem,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Grid,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Message } from "../../../framework/main/Message";
import MessageEnum, {
  getName,
} from "../../../framework/main/Messages/MessageEnum";
import AboutController, { Props } from "./AboutController";
import { ErrorMessage, Formik } from "formik";
import { companyAboutSchema } from "../utils/schema";
import { styles } from "../utils/styles";
import Layout from "./Layout.web";
import CarouselDisplay from "../../../blocks/carouseldisplay/main/CarouselDisplay.web";
import { meunuProps } from "./About.web";

export type AboutValues = {
  size: string;
  aboutUs: string;
  selectedCheckboxIds: any;
};

// initial formik values
const initialValues: AboutValues = {
  size: "",
  aboutUs: "",
  selectedCheckboxIds: [],
};

export class CompanyAbout extends AboutController {
  constructor(props: Props) {
    super(props);
  }

  areAnyChecked = () => {
    return this.state.gtmChallengeValue.some((value) => value);
  };

  onCompanyAboutBack = () => {
    const message: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "CompanyDetail"
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };

  render() {
    const { classes } = this.props;

    return (
      <>
        <Box className={classes.layoutContainer}>
          <Box className={classes.viewBox}>
            <Typography className={classes.createAccount}>
              Tell us a bit more
            </Typography>
            <Typography className={classes.signingAs}>
              Just a few more details and we’re set.
            </Typography>
            <Formik
              data-testid="companyAboutFormik"
              initialValues={initialValues}
              validationSchema={companyAboutSchema}
              onSubmit={(values) => {
                this.comapnyAboutPost(values);
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleSubmit,
                setFieldValue,
              }) => (
                <form onSubmit={handleSubmit} data-testid="formSubmits">
                  <Box className={classes.form1}>
                    <Typography className={classes.label}>
                      What is the size of your GTM team?
                    </Typography>
                    <div style={{ marginBottom: "24px" }}>
                      <Select
                        fullWidth
                        name="size"
                        value={values.size}
                        onChange={handleChange}
                        displayEmpty
                        disableUnderline
                        inputProps={{ "aria-label": "company-size" }}
                        MenuProps={meunuProps}
                        className={`${classes.select} ${values.size ? "" : classes.inactiveSelect1
                          }`}
                        data-testid="sizeInput"
                        style={{
                          borderColor:
                            touched.size && errors.size ? "#F87171" : "",
                        }}
                      >
                        {this.state.companySize.map((detail) => (
                          <MenuItem
                            className={classes.aboutmenuItem}
                            value={detail.id}
                          >
                            {detail.size}
                          </MenuItem>
                        ))}
                      </Select>
                      <ErrorMessage
                        name="size"
                        component="div"
                        className={classes.errorResp}
                      />
                    </div>
                    <Typography className={classes.label}>
                      What are your biggest GTM challenges?
                    </Typography>
                    <div style={{ marginBottom: "24px" }}>
                      <FormGroup style={{ marginTop: "12px" }}>
                        <Grid container spacing={2}>
                          {this.state.gtmChallenge
                            .slice(0, this.state.displayCount)
                            .map((checkbox) => (
                              <Grid item xs={12} md={6} key={checkbox.id}>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={this.state.gtmChallengeValue.includes(
                                        checkbox.id
                                      )}
                                      onChange={() => {
                                        let selectedCheckbox: any = this.handleCheckboxChange(
                                          checkbox.id
                                        );
                                        setFieldValue(
                                          "selectedCheckboxIds",
                                          selectedCheckbox || []
                                        );
                                      }}
                                      name="selectedCheckboxIds"
                                      data-testid="checkinput"
                                      style={{
                                        color: this.areAnyChecked()
                                          ? "#3A84C9"
                                          : "",
                                      }}
                                    />
                                  }
                                  label={
                                    <Typography
                                      variant="h6"
                                      style={{
                                        fontSize: "14px",
                                        fontWeight: 500,
                                      }}
                                    >
                                      {checkbox.name}
                                    </Typography>
                                  }
                                />
                              </Grid>
                            ))}
                          <Grid item xs={6}>
                            <Button
                              onClick={this.handleToggleDisplay}
                              variant="text"
                              style={{
                                color: "#3A84C9",
                                fontSize: "14px",
                                fontWeight: 600,
                                textTransform: "none",
                              }}
                            >
                              {this.state.displayCount ===
                                this.state.initialDisplayCount
                                ? "Show more"
                                : "Show less"}
                            </Button>
                          </Grid>
                        </Grid>
                        <Grid>
                          <ErrorMessage
                            name="selectedCheckboxIds"
                            component="div"
                            className={classes.errorResp}
                          />
                        </Grid>
                      </FormGroup>
                    </div>

                    <Typography className={classes.label}>
                      How did you hear about us?
                    </Typography>
                    <div style={{ marginBottom: "24px" }}>
                      <Select
                        fullWidth
                        name="aboutUs"
                        value={values.aboutUs}
                        onChange={handleChange}
                        displayEmpty
                        disableUnderline
                        inputProps={{ "aria-label": "company-size" }}
                        MenuProps={meunuProps}
                        className={`${classes.select} ${values.aboutUs ? "" : classes.inactiveSelect1
                          }`}
                        data-testid="aboutFields"
                        style={{
                          borderColor:
                            touched.aboutUs && errors.aboutUs
                              ? "#F87171"
                              : "",
                        }}
                      >
                        {this.state.hereAbout.map((detail) => (
                          <MenuItem
                            className={classes.aboutmenuItem}
                            value={detail.id}
                          >
                            {detail.name}
                          </MenuItem>
                        ))}
                      </Select>
                      <ErrorMessage
                        name="aboutUs"
                        component="div"
                        className={classes.errorResp}
                      />
                    </div>
                    <Button
                      variant="contained"
                      type="submit"
                      className={classes.buttonWrapper}
                      data-testid="formSubmits"
                    >
                      Continue
                    </Button>
                  </Box>
                </form>
              )}
            </Formik>
          </Box>
        </Box>
      </>
    );
  }
}

export default withStyles(styles)(CompanyAbout);
