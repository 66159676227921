import React, { useEffect, useState } from 'react';

import { Backdrop, Box, Button, CircularProgress, FormHelperText, IconButton, Typography, styled } from '@material-ui/core';
import Dropdown from '../../../../../../components/main/CustomFields/Dropdown/index';
import { ReactComponent as DeleteButton } from '../../../../../../assets/icons/delete.svg';
import { InsertDriveFile } from '@material-ui/icons';
import { getCompanyIntelAttachments, uploadCompanyIntelInformation } from '../../../../../../redux/services/company-intel';
import { getProfile } from '../../../../../../redux/services/profile';

import { useStyles } from './styles';
import { notify } from '../../../../../../providers/notification';

interface Props {
  item: any;
  name: string;
  values: any;
  errors: any;
  touched: any;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  setFieldError: (field: string, message: string) => void;
  setFieldTouched: (field: string, isTouched?: boolean, shouldValidate?: boolean) => void;
}

const RenderFileUpload: React.FC<Props> = ({
  item,
  name,
  values,
  errors,
  touched,
  setFieldValue,
  setFieldError,
}) => {
  const classes = useStyles();

  const [loading, setLoading] = useState<boolean>(false);
  const [intels, setIntels] = useState<any>([]);
  const [profile, setProfile] = useState<any>(null);

  useEffect(() => {
    handlerGetCompanyIntels();
    handlerGetProfile();
  }, []);

  const handlerGetCompanyIntels = async () => {
    const { data: company, error: companyError } = await getCompanyIntelAttachments({
      fileName: '',
      informationType: 'COMPANY'
    });
    const { data: audience, error: audienceError } = await getCompanyIntelAttachments({
      fileName: '',
      informationType: 'AUDIENCE'
    });

    let intels = [];

    if (!companyError && Array.isArray(company)) {
      intels = [
        ...intels,
        ...company
      ]
    }

    if (!audienceError && Array.isArray(company)) {
      intels = [
        ...intels,
        ...audience
      ]
    }

    setIntels(intels);

    return intels;
  }

  const handlerGetProfile = async () => {
    const { data, error } = await getProfile();

    if (data && !error) {
      setProfile(data);
    }
  }

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setLoading(true);

    const file = event.target.files && event.target.files[0];

    if (file) {
      const validExtensions = ['text/plain', 'application/pdf', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];
      const maxSize = 25 * 1024 * 1024; // 25MB in bytes

      if (!validExtensions.includes(file.type)) {
        setFieldError(name, 'Invalid file type. Please upload a .txt, .docx, or .pdf file.');
      } else if (file.size > maxSize) {
        setFieldError(name, 'File size exceeds 25MB. Please upload a smaller file.');
      } else {
        const { data, error } = await uploadCompanyIntelInformation({
          file,
          type: 'Something else...'
        });

        if (!error) {
          const intels = await handlerGetCompanyIntels();

          const { filename } = intels.find(item => item.filename === file.name);

          setFieldValue(
            name,
            {
              [item.provideOnUpload[0]]: filename,
              [item.provideOnUpload[1]]: profile?.account?.uuid,
            }
          );

          notify.success('Document uploaded successfully');
        } else {
          notify.error('Document failed in the uploading process');
        }
      }
    }

    setLoading(false);
  };

  const getColor = (name: string) => {
    if (name.includes(".pdf")) {
      return "#E04E4E";
    }

    if (name.includes(".docx")) {
      return "#3166ED";
    }

    return "#00B84A";
  };

  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });

  return (
    <>
      <Backdrop
        style={{ color: '#fff', zIndex: 9999 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Box className={classes.fileUploadWrapper}>
        {values[name] && !!Object.keys(values[name]).length
          ? <Box className={classes.uploadedFileWrapper}>
              <InsertDriveFile
                style={{
                  color: getColor(values[name].file_name),
                }}
              />
              <Typography noWrap style={{ fontSize: '14px', fontWeight: '600', lineHeight: '17px' }}>
                {values[name].file_name}
              </Typography>
              <IconButton
                onClick={() => setFieldValue(name, {})}
              >
                <DeleteButton />
              </IconButton>
            </Box>
          : <>
              <Dropdown
                name={name}
                value={values[name]?.file_name}
                placeholder={'Choose document from company intel...'}
                options={intels.map(item => ({ value: item.filename, label: item.filename }))}
                onChange={(event) => {
                  setFieldValue(
                    event.target.name,
                    {
                      [item.provideOnUpload[0]]: event.target.value,
                      [item.provideOnUpload[1]]: profile?.account?.uuid,
                    }
                  );
                }}
              />
              <Button
                component="label"
                variant="contained"
                color="secondary"
                tabIndex={-1}
                role={undefined}
              >
                {item.attributes.find(item => item.type === 'PLACEHOLDER_ATTRIBUTE')?.text || 'Upload'}
                <VisuallyHiddenInput
                  type="file"
                  accept=".txt,.docx,.pdf"
                  onChange={handleFileChange}
                />
              </Button>
            </>
        }
      </Box>

      {touched[name] && errors[name] && (
        <FormHelperText error>
          {errors[name]}
        </FormHelperText>
      )}
    </>
  );
};

export default RenderFileUpload;
