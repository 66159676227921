import React, { useEffect, useState } from 'react';
import * as Yup from "yup";
import moment from "moment";
import { Modal, Box, IconButton, Typography, Button, CircularProgress as LoadingCircularProgress, Backdrop, CircularProgress } from '@material-ui/core';
import { ReactComponent as CloseIcon } from '../../../../../assets/icons/close.svg';
import { createCustomTopUp, createTopUpOrder, getTopUps } from '../../../../../redux/services/wallet';
import { formatCurrency, getCurrencySybmol } from '../../../../../helpers/curency';
import { getCurrentSubscription } from '../../../../../redux/services/subscriptions';
import TextField from '../../../CustomFields/TextField';
import Dropdown from '../../../CustomFields/Dropdown';
import { Form, Formik } from 'formik';
import { notify } from '../../../../../providers/notification';

import { useStyles } from './styles';

interface Props {
  open: boolean;
  type?: 'ONBOARDING' | 'COPILOT' | 'COPILOT_INTELLIGENCE' | 'TACTICAL_OUTREACH';
  limitReached: boolean;
  hideParent?: boolean;
  onClose: () => void;
  showPaymentModal: (option: any) => void;
}

const TopUpModal: React.FC<Props> = (props) => {
  const {
    open,
    type,
    limitReached,
    hideParent = false,
    onClose,
    showPaymentModal,
  } = props;

  const [loading, setLoading] = useState<boolean>(false);
  const [topUps, setTopUps] = useState<any>(null);
  const [customPricingRules, setCustomPricingRules] = useState<any>(null);
  const [currentSubscription, setCurrentSubscription] = useState<any>(null);

  const classes = useStyles();

  useEffect(() => {
    if (open) {
      handleGetTopUps();
      handleGetCurrentSubscription();
    }
  }, [open, type]);

  useEffect(() => {
    return () => {
      setTopUps(null);
      setCurrentSubscription(null);
    }
  }, [open]);

  const handleGetTopUps = async () => {
    const { data, error } = await getTopUps();

    if (data && !error) {
      setTopUps(data.presetTopUps);
      setCustomPricingRules(data.customPricingRules);
    }
  }

  const handleCreateTopUpOrder = async (item) => {
    setLoading(true);

    const { data, error } = await createTopUpOrder({ id: item.id, productType: 'TOP_UP' });

    if (data && !error) {
      showPaymentModal({
        id: data.id,
        name: `You are about to buy @amount worth of pebbles ${type ? `to ${type === 'ONBOARDING' ? 'apply more onboarding edits.' : (type === 'COPILOT' || type === 'COPILOT_INTELLIGENCE') ? 'generate more content.' : 'create more tactical emails.'}` : ''}`,
        currency: data.currency,
        amount: data.amount,
        token: data.token
      })
    } else {
      notify.error('Failed to create order');
    }

    setLoading(false);
  }

  const handleCreateCustomTopUpOrder = async (values) => {
    if (!isBuyButtonDisabled(values['amount'])) {
      setLoading(true);

      const { data: customTopUp, error: customTopUpError } = await createCustomTopUp({ amount: values.amount });

      if (customTopUp && !customTopUpError) {
        const { data, error } = await createTopUpOrder({ id: customTopUp.id, productType: 'CUSTOM_TOP_UP' });
  
        if (data && !error) {
          showPaymentModal({
            id: data.id,
            name: `You are about to buy @amount worth of pebbles`,
            currency: data.currency,
            amount: data.amount,
            token: data.token
          })
        } else {
          notify.error('Failed to create order');
        }

        setLoading(false);
      } else {
        notify.error('Failed to create custom top-up');
      }
    }
  }

  const handleGetCurrentSubscription = async () => {
    const { data, error } = await getCurrentSubscription();

    if (data && !error) {
      setCurrentSubscription(data);
    }
  }

  const findPriceObject = (amount) => {
    return customPricingRules.find(item => {
      const min = item.range.min;
      const max = item.range.max;

      return amount >= min && (max === null || amount <= max);
    });
  }

  const getHelperText = (amount) => {
    if (customPricingRules?.length) {
      const benefitTokens = Math.floor((findPriceObject(amount)?.bonusPercents / 100) * amount);

      if (benefitTokens) {
        return `(+${benefitTokens} free tokens)`;
      } else {
        return ``;
      }
    } else {
      return ``;
    }
  }

  const isBuyButtonDisabled = (value) => {
    return !value || +value === 0 || `${value}`.includes('.');
  }

  return (
    <Modal 
      open={open} 
      onClose={onClose} 
      className='MuiDialog-root'
      style={{
        visibility: hideParent ? 'hidden' : 'visible'
      }}
    >
      <Box 
        className={classes.modalBox}
        style={{
          pointerEvents: loading ? 'none' : 'all'
        }}
      >
        <IconButton onClick={onClose} className={classes.closeButton}>
          <CloseIcon />
        </IconButton>
        <Typography style={{ fontSize: '30px', fontWeight: '700', lineHeight: '32px', textAlign: 'center', }}>
          {type
            ? limitReached
              ? `You are unable to ${type === 'ONBOARDING' ? 'edit onboarding' : type === 'COPILOT' ? 'send copilot message' : 'generate email'} until your limit resets on ${moment(currentSubscription?.validTill).format('DD-MMM-YYYY')}.`
              : type === 'ONBOARDING'
                ? 'Buy more pebbles to apply more onboarding edits'
                : type === 'COPILOT' || type === 'COPILOT_INTELLIGENCE'
                  ? 'Buy more pebbles to generate more content'
                  : 'Buy more pebbles to create more tactical emails'
            : 'Buy more pebbles'
          }
        </Typography>
        <Box className={classes.content}>
          <Typography style={{ fontSize: '16px', fontWeight: '500', lineHeight: '19px', color: '#475569' }}>
            Choose to top up your limits
          </Typography>
          <Box className={classes.optionsWrapper}>
            {!topUps
              ? <LoadingCircularProgress
                  color="inherit"
                  style={{ margin: '0 auto' }}
                />
              : topUps.map((item, index) => (
                  <Box className={classes.optionItem} key={index}>
                    <Typography style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'center', gap: '4px', paddingBottom: '20px', borderBottom: '1px solid #CBD5E1', fontSize: '40px', fontWeight: '500', lineHeight: '40px', color: '#22507B' }}>
                      +{item.amount}
                      <Typography style={{ fontSize: '12px', fontWeight: '600', lineHeight: '20px', color: '#475569' }}>
                        pebbles
                      </Typography>
                    </Typography>
                    <Typography style={{ display: 'flex', justifyContent: 'center', gap: '4px', fontSize: '28px', fontWeight: '500', lineHeight: '28px' }}>
                      <Typography style={{ fontSize: '14px', fontWeight: '500', lineHeight: '14px' }}>
                        {getCurrencySybmol(item.currency)}
                      </Typography>
                      {formatCurrency(item.price)}
                    </Typography>
                    <Button
                      color='secondary'
                      variant='outlined'
                      style={{ marginTop: '20px' }}
                      onClick={() => handleCreateTopUpOrder(item)}
                    >
                      Buy
                    </Button>
                  </Box>
                ))
            }
          </Box>
          <Typography style={{ marginTop: '30px', display: 'flex', alignItems: 'center', gap: '4px', fontSize: '16px', fontWeight: '500', lineHeight: '19px', color: '#475569' }}>
            Custom amount |
            <Typography style={{ fontSize: '16px', fontWeight: '500', lineHeight: '19px', color: '#22507B' }}>
              Top up to <b>500</b> and get an extra <b>50 pebbles</b> for free!
            </Typography>
          </Typography>
          <Formik
            initialValues={{
              amount: null,
              price: formatCurrency(0),
            }}
            validationSchema={Yup.object().shape({
              amount: Yup.number()
                .nullable(true)
                .required('This field is required'),
              price: Yup.string(),
            })}
            validateOnChange={true}
            validateOnBlur={false}
            isInitialValid={false}
            onSubmit={(values: any) => handleCreateCustomTopUpOrder(values)}
          >
            {({
              values,
              errors,
              touched,
              setFieldValue,
              setFieldError,
              setFieldTouched
            }) => (
              <Form
                translate={undefined}
              >
                <Box style={{ display: 'flex', flexDirection: 'column', gap: '16px', padding: '20px', background: '#F8FAFC', borderRadius: '20px' }}>
                  <Box style={{ display: 'flex', gap: '20px' }}>
                    <Box style={{ width: '65%', display: 'flex', flexDirection: 'column', gap: '4px' }}>
                      <Typography style={{ display: 'flex', alignItems: 'center', gap: '5px', fontSize: '16px', fontWeight: '600', lineHeight: '19px' }}>
                        Amount of pebbles
                        <Typography style={{ fontSize: '0.75rem', fontWeight: '400', lineHeight: '1.55', color: '#0000008a' }}>
                          {getHelperText(values['amount'])}
                        </Typography>
                      </Typography>
                      <TextField
                        value={values['amount']}
                        type='number'
                        placeholder='Enter the amount of pebbles you want to buy...'
                        // error={(touched['amount'] && errors['amount']) as string}
                        onChange={(event) => {
                          setFieldValue('amount', event.target.value);
                          const currentPriceObject = findPriceObject(event.target.value);
                        
                          if (currentPriceObject) {
                            setFieldValue('price', formatCurrency(event.target.value * currentPriceObject.pricePerPebble))
                          } else {
                            setFieldValue('price', formatCurrency(0))
                          }
                        }}
                      />
                    </Box>
                    <Box style={{ width: '35%', display: 'flex', flexDirection: 'column', gap: '4px' }}>
                      <Typography style={{ fontSize: '16px', fontWeight: '600', lineHeight: '19px' }}>
                        Price & Currency
                      </Typography>
                      <Box style={{ display: 'flex', alignItems: 'center' }}>
                        <Typography style={{ fontSize: '16px', fontWeight: '500', lineHeight: '52px' }}>
                          {getCurrencySybmol('GBP')}&nbsp;
                          {values['price']}&nbsp;
                          total
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                  <Button
                    fullWidth
                    color='secondary'
                    variant='outlined'
                    type='submit'
                    disabled={isBuyButtonDisabled(values['amount'])}
                  >
                    Buy
                  </Button>
                </Box>
              </Form>
            )}
          </Formik>
        </Box>
      </Box>
    </Modal>
  );
};

export default TopUpModal;
