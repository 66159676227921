import { api, apiCall, createApiCall } from "../api";
import { UserManagementRequest, UserManagementResponse } from "../../interfaces/user-management";
import { AuthResponse, PasswordResetRequest } from "../../interfaces/authentication";

const profileAPI = api.injectEndpoints({
  endpoints: (builder) => ({
    getAccountUsers: builder.query<UserManagementResponse[], void>({
      query: () => ({
        url: "/users",
        method: "GET"
      })
    }),
    inviteAccountUser: builder.mutation<void, UserManagementRequest>({
      query: (body) => ({
        url: "/users",
        method: "POST",
        body
      })
    }),
    deleteAccountUser: builder.mutation<void, string>({
      query: (userId) => ({
        url: `/users/${userId}`,
        method: "DELETE"
      })
    }),
    updateAccountUser: builder.mutation<void, { userId: string, body: { role: string } }>({
      query: ({ userId, body }) => ({
        url: `/users/${userId}`,
        method: "PUT",
        body
      })
    }),
    saveNewAccountUserPassword: builder.mutation<AuthResponse, { userId: string, body: PasswordResetRequest }>({
      query: ({ userId, body }) => ({
        url: `/users/${userId}/password/temporary`,
        method: "PUT",
        body
      })
    }),
    deactivateAccountUser: builder.mutation<void, string>({
      query: (userId) => ({
        url: `/users/${userId}/deactivate`,
        method: "PUT"
      })
    }),
    activateAccountUser: builder.mutation<void, string>({
      query: (userId) => ({
        url: `/users/${userId}/activate`,
        method: "PUT"
      })
    })
  }),
  overrideExisting: true
});

// Create a function to call the API without using the hook
export const getAccountUsers = createApiCall<UserManagementResponse[], void>(profileAPI.endpoints.getAccountUsers);
export const inviteAccountUser = createApiCall<void, UserManagementRequest>(profileAPI.endpoints.inviteAccountUser);
export const deleteAccountUser = createApiCall<void, string>(profileAPI.endpoints.deleteAccountUser);
export const updateAccountUser = createApiCall<void, { userId: string, body: { role: string } }>(profileAPI.endpoints.updateAccountUser);
export const saveNewAccountUserPassword = createApiCall<AuthResponse, { userId: string, body: PasswordResetRequest }>(profileAPI.endpoints.saveNewAccountUserPassword);
export const deactivateAccountUser = createApiCall<void, string>(profileAPI.endpoints.deactivateAccountUser);
export const activateAccountUser = createApiCall<void, string>(profileAPI.endpoints.activateAccountUser);
