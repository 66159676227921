import React from 'react';
import _ from 'lodash';
import { Box, Typography } from '@material-ui/core';
import TextField from '../../../../components/main/CustomFields/TextField';

import { useStyles } from './styles';
import { isStartUpTeamsSubscription } from '../../../../helpers/roles';

interface Props {
  campaigns: any;
  searchTerm: string;
  subscription: any;
  currentTab: 'all' | 'approved' | 'in_progress';
  setCurrentTab: (tab: 'all' | 'approved' | 'in_progress') => void;
  setCampaigns: (newCampaigns: any) => void;
  isSubscriptionActive: boolean;
  isOnboardingCompleted: boolean;
  handleSearchChange: (event: any) => void;
}

const Filters: React.FC<Props> = ({
  campaigns,
  searchTerm,
  subscription,
  currentTab,
  setCurrentTab,
  setCampaigns,
  isSubscriptionActive,
  isOnboardingCompleted,
  handleSearchChange,
}) => {
  const classes = useStyles();

  return (
    <Box className={classes.filters}>
      <Box className={classes.filtersLeftBlock}>
        {isStartUpTeamsSubscription(subscription) && (
          <Box className={classes.tabsBlock}>
            <Box 
              className={`${classes.tabsItem} ${currentTab === 'approved' ? classes.tabsItemActive : ''}`}
              onClick={() => {
                setCurrentTab('approved');
                setCampaigns(null);
              }}
            >
              Approved
            </Box>
            <Box 
              className={`${classes.tabsItem} ${currentTab === 'in_progress' ? classes.tabsItemActive : ''}`}
              onClick={() => {
                setCurrentTab('in_progress');
                setCampaigns(null);
              }}
            >
              In progress
            </Box>
          </Box>
        )}
      </Box>
      <Box 
        className={classes.filtersRightBlock}
        // style={{
        //   opacity: (!isOnboardingCompleted || !isSubscriptionActive) ? '0.5' : '1',
        //   pointerEvents: (!isOnboardingCompleted || !isSubscriptionActive) ? 'none' : 'all',
        // }}
      >
        <TextField
          type={'search'}
          placeholder={'Search campaigns'}
          value={searchTerm}
          showClear
          onChange={handleSearchChange}
          onSearchPress={() => { }}
          onKeyPress={() => { }}
        />
      </Box>
    </Box>
  );
};

export default Filters;
