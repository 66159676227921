import React from 'react';
import { Box, Chip, FormHelperText, IconButton, Typography } from '@material-ui/core';
import { ReactComponent as InfoHintMiniIcon } from '../../../../../../assets/icons/info_hint_mini.svg';
import { ReactComponent as ShortEmailIcon } from '../../../../../../assets/icons/short_email_length.svg';
import { ReactComponent as BalancedEmailIcon } from '../../../../../../assets/icons/balanced_email_length.svg';
import { ReactComponent as ComprehensiveEmailIcon } from '../../../../../../assets/icons/comprehensive_email_length.svg';

import { useStyles } from './styles';
import { notify } from '../../../../../../providers/notification';

interface Props {
  item: any;
  name: string;
  values: any;
  errors: any;
  touched: any;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  setFieldError: (field: string, message: string) => void;
  setTooltipMessage: (message: string) => void;
}

const RenderBoxSwitchSelector: React.FC<Props> = ({
  item,
  name,
  values,
  errors,
  touched,
  setFieldValue,
  setFieldError,
  setTooltipMessage,
}) => {
  const classes = useStyles();

  const handleSelect = (elem) => {
    const labelUnderConditionAttributes = item.attributes.filter(item => item.type === 'LABEL_UNDER_CONDITION_ATTRIBUTE');

    if (labelUnderConditionAttributes?.length) {
      const currentLabelAttribute = labelUnderConditionAttributes.find(item => values[Object.values(item.elementReference).join('-')] === item.option)

      if (currentLabelAttribute) {
        const newValue = {
          label: currentLabelAttribute.label,
          value: elem
        }

        if (JSON.stringify(values[name]) === JSON.stringify(newValue)) {
          setFieldValue(name, {});
        } else {
          setFieldValue(name, newValue);
        }
      } else {
        notify.info('Choose type of email first');

        const element = document.getElementById('1-1-1');

        if (element) {
          element.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'center'
          });
        }
      }
    }
  }

  const handleShowTooltip = (event, elem) => {
    event.stopPropagation();

    const tooltipAttribute: any = Object.values(item.attributes).find((item: any) => item.type === 'ON_OPTION_TOOLTIP_ATTRIBUTE');
    const message = tooltipAttribute.optionTooltipMap[elem];

    setTooltipMessage(message);
  }

  const getIconPerItem = (elem) => {
    switch (elem) {
      case 'Short':
        return <ShortEmailIcon />
      case 'Balanced':
        return <BalancedEmailIcon />
      case 'Comprehensive':
        return <ComprehensiveEmailIcon />
      default:
        return <Box />
    }
  }
  
  return (
    <>
      <Box className={classes.multiSelectorWrapper}>
        {item.options.map((elem, index) => (
          <Box 
            key={index} 
            className={`${classes.chip} ${values[name]?.value === elem ? classes.chipSelected : ''}`}
            onClick={() => handleSelect(elem)}
          >
            <Box className={classes.topBox}>
              {getIconPerItem(elem)}
              <IconButton
                size='small'
                onClick={(event) => handleShowTooltip(event, elem)}
              >
                <InfoHintMiniIcon />
              </IconButton>
            </Box>
            <Box className={classes.bottomBox}>
              <Typography style={{ fontSize: '14px', fontWeight: '500', lineHeight: '21px', color: values[name] === elem ? '#22507B' : '#0F172A' }}>
                {elem}
              </Typography>
              <Typography style={{ fontSize: '12px', fontWeight: '400', lineHeight: '14px', color: '#475569' }}>
                {item.attributes.find(item => item.type === "ON_OPTION_INFO_ATTRIBUTE").optionInfoMap[elem]}
              </Typography>
            </Box>
          </Box>
        ))}
      </Box>

      {touched[name] && errors[name] && (
        <FormHelperText error>
          {errors[name]}
        </FormHelperText>
      )}
    </>
  );
};

export default RenderBoxSwitchSelector;
