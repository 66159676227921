import React from 'react';
import _ from 'lodash';
import moment from 'moment-timezone';
import { Avatar, Box, colors, IconButton, TableCell, TableRow, Tooltip, Typography } from '@material-ui/core';
import { ReactComponent as DeleteIcon } from '../../../../assets/icons/delete.svg';
import { ReactComponent as ArrowDownIcon } from '../../../../assets/icons/arrow_down_filled.svg';
import { ReactComponent as ArrowUpIcon } from '../../../../assets/icons/arrow_up_filled.svg';
import { ReactComponent as LatterIcon } from '../../../../assets/icons/latter.svg';
import { ReactComponent as EditIcon } from '../../../../assets/icons/edit_dark.svg';
import { ReactComponent as DuplicateIcon } from '../../../../assets/icons/duplicate_icon.svg';
import { ReactComponent as BlueCheckmarkIcon } from '../../../../assets/icons/blue_checkmark.svg';
import { ReactComponent as DraftCampaignIcon } from '../../../../assets/icons/draft_campaign_icon.svg';
import TextField from '../../../../components/main/CustomFields/TextField';
import { trimTextByCharacters } from '../../../../helpers/text';
import { convertHTMLToText, convertMarkdownToText, isHTML, isMarkdown } from '../../../TacticalOutreachEmail/helpers';
import { isStartUpTeamsSubscription, showViewForAdmin } from '../../../../helpers/roles';
import { useHistory } from 'react-router-dom';
import { ReactComponent as ClockIcon } from '../../../../assets/icons/clock_icon.svg';
import { ReactComponent as CloseRedIcon } from '../../../../assets/icons/close_red.svg';
import { REVIEW_STATUS } from '../../../../utils/enums';

import { useStyles } from './styles';
import { notify } from '../../../../providers/notification';

interface Props {
  item: any;
  profile: any;
  subscription: any;
  editSubject: any;
  isSubscriptionActive: boolean;
  isOnboardingCompleted: boolean;
  currentTab: 'all' | 'approved' | 'in_progress';
  handleExpandCampaign: (event: any, id: number, value?: boolean) => void;
  setDeleteSubject: (data: { isCampaign: boolean, campaignId: number, emailId?: number }) => void;
  setEditSubject: (data: { campaignId: number, value: string, field: string }) => void;
  handleSaveEdit: () => void;
  handleDuplicate: (data: { isCampaign: boolean, campaignId: number, emailId?: number }) => void;
  setShowActionBlockedModal: (open: boolean) => void;
}

const CampaignTableRow: React.FC<Props> = ({
  item,
  profile,
  subscription,
  editSubject,
  isSubscriptionActive,
  isOnboardingCompleted,
  currentTab,
  handleExpandCampaign,
  setDeleteSubject,
  setEditSubject,
  handleSaveEdit,
  handleDuplicate,
  setShowActionBlockedModal,
}) => {

  const history = useHistory();
  const classes = useStyles({ item });

  const userTimezone = moment.tz.guess();

  const renderCampaignStatus = () => {
    let innerText = null;
    let color = null;

    if (item.status === 'INCOMPLETED') {
      innerText = '[Incomplete]';
      color = '#D97706';
    } else if (currentTab === 'in_progress') {
      switch (item.reviewStatus) {
        case REVIEW_STATUS.DRAFT:
          innerText = '[Draft]';
          color = '#8C8C8C';
          break;
        case REVIEW_STATUS.PENDING_REVIEW:
          innerText = '[Pending review]';
          color = '#3A84C9';
          break;
        case REVIEW_STATUS.CHANGE_REQUIRED:
          innerText = '[Changes  required]';
          color = '#F59E0B';
          break;
        case REVIEW_STATUS.REJECTED:
          innerText = '[Rejected]';
          color = '#DC2626';
          break;
        default:
          innerText = null;
          color = null;
          break;
      }
    }

    return (
      innerText
        ? <Typography noWrap style={{ fontSize: '14px', fontWeight: '500', lineHeight: '21px', color: color }}>
          {innerText}
        </Typography>
        : null
    )
  }

  const renderCampaignExpandIndicator = () => {
    let background = null;
    let color = null;
    let icon = null;

    if (currentTab === 'in_progress') {
      if (item.reviewStatus === REVIEW_STATUS.REJECTED) {
        // show grey block with red cross inside
        background = '#F8FAFC';
        color = 'red';
        icon = <CloseRedIcon />;
      } else {
        // show grey block with grey clock inside
        background = '#F8FAFC';
        color = '#DADADA';
        icon = <ClockIcon />;
      }
    } else {
      if (!item.emails?.length || item.status === 'INCOMPLETED') {
        // show yellow block with orange documents and clock inside
        background = '#FEF3C7';
        color = '#D97706';
        icon = <DraftCampaignIcon />;
      } else {
        // show up and down icons indicates expanded and collapsed state
        background = item.expanded ? '#FFFFFF' : '#F1F5F9';
        color = '#475569';
        icon = item.expanded ? <ArrowUpIcon className={classes.arrowIcon} /> : <ArrowDownIcon className={classes.arrowIcon} />
      }
    }

    return (
      <Box
        className={classes.iconBox}
        style={{
          background: background
        }}
      >
        {!!item.emails?.length && item.status === 'COMPLETED' && (
          <Typography
            className={classes.iconText}
            style={{
              color: color
            }}
          >
            {item.emails?.length}
          </Typography>
        )}
        {icon}
      </Box>
    )
  }

  return (
    <>
      <TableRow
        className={classes.tableRow}
        onClick={(event) => {
          if (item.status === 'INCOMPLETED' || currentTab === 'in_progress') {
            if (!isOnboardingCompleted || !isSubscriptionActive) {
              setShowActionBlockedModal(true);
            } else {
              if (item.status === 'COMPLETED' || item.emails?.length) {
                history.push(`/tactical-outreach/email?campaign_id=${item.id}`)
              } else {
                history.push(`/tactical-outreach/builder?campaign_id=${item.id}`)
              }
            }
          } else {
            handleExpandCampaign(event, item.id)
          }
        }}
      >
        <TableCell
          align="left"
          className={classes.tableCellMain}
        >
          <Box
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '16px',
            }}
          >
            {renderCampaignExpandIndicator()}
            <Box className={classes.contentBox}>
              {editSubject?.campaignId === item.id && editSubject?.field === 'name'
                ? <Box
                  className={classes.expandedTableCellMain}
                  onClick={(event) => event.stopPropagation()}
                  style={{
                    width: '85%',
                    minWidth: '85%'
                  }}
                >
                  <TextField
                    value={editSubject.value}
                    placeholder={'Name'}
                    autoFocus
                    // error={editSubject.value.trim().length > 50 ? 'Max 50 characters' : ''}
                    onChange={(event) => {
                      setEditSubject({ ...editSubject, value: event.target.value })
                    }}
                  />
                  <IconButton
                    size='small'
                    onClick={(event) => {
                      event.stopPropagation();

                      if (editSubject.value.trim().length > 50) {
                        notify.error('Max 50 characters');
                      } else {
                        if (!isOnboardingCompleted || !isSubscriptionActive) {
                          setShowActionBlockedModal(true);
                        } else {
                          handleSaveEdit();
                        }
                      }
                    }}
                  >
                    <BlueCheckmarkIcon />
                  </IconButton>
                </Box>
                : <Box className={classes.expandedTableCellMain}>
                  {renderCampaignStatus()}
                  <Typography
                    noWrap
                    className={classes.contentTitle}
                    style={{
                      marginLeft: renderCampaignStatus() === null ? '0px' : '-10px'
                    }}
                  >
                    {item.name}
                  </Typography>
                  {!isStartUpTeamsSubscription(subscription) || (item.createdBy === profile.user.uuid || showViewForAdmin(subscription)) && (
                    <IconButton
                      className={classes.contentTitleIcon}
                      onClick={(event) => {
                        event.stopPropagation();

                        setEditSubject({ campaignId: item.id, value: item.name, field: 'name' })
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                  )}
                </Box>
              }
              <Typography className={classes.contentDate}>
                Created: {moment.utc(item.createdAt).tz(userTimezone).format('DD-MMM-YYYY HH:mm')}
              </Typography>
            </Box>
          </Box>
        </TableCell>
        <TableCell
          align="left"
          className={classes.tableCell}
        // style={{
        //   display: 'flex',
        //   maxHeight: 'fit-content',
        //   minHeight: 'fit-content',
        // }}
        >
          <Box style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            <Typography noWrap className={classes.descriptionText}>
              {editSubject?.campaignId === item.id && editSubject?.field === 'description'
                ? <Box
                  className={classes.expandedTableCellMain}
                  onClick={(event) => event.stopPropagation()}
                  style={{
                    width: '80%',
                    minWidth: '80%'
                  }}
                >
                  <TextField
                    value={editSubject.value}
                    placeholder={'Description'}
                    autoFocus
                    // error={editSubject.value.trim().length > 300 ? 'Max 300 characters' : ''}
                    onChange={(event) => {
                      setEditSubject({ ...editSubject, value: event.target.value })
                    }}
                  />
                  <IconButton
                    size='small'
                    onClick={(event) => {
                      event.stopPropagation();

                      if (editSubject.value.trim().length > 300) {
                        notify.error('Max 300 characters');
                      } else {
                        if (!isOnboardingCompleted || !isSubscriptionActive) {
                          setShowActionBlockedModal(true);
                        } else {
                          handleSaveEdit();
                        }
                      }
                    }}
                  >
                    <BlueCheckmarkIcon />
                  </IconButton>
                </Box>
                : <Box className={classes.expandedTableCellMain}>
                  <Typography className={classes.contentDescription}>
                    {trimTextByCharacters(item.description, 140)}
                  </Typography>
                  {!isStartUpTeamsSubscription(subscription) || (item.createdBy === profile.user.uuid || showViewForAdmin(subscription)) && (
                    <IconButton
                      className={classes.contentDescriptionEdit}
                      onClick={(event) => {
                        event.stopPropagation();

                        setEditSubject({ campaignId: item.id, value: item.description, field: 'description' })
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                  )}
                </Box>
              }
            </Typography>
            {item.type && currentTab === 'all' && (
              <Typography
                style={{
                  width: 'fit-content',
                  fontSize: '12px',
                  fontWeight: '500',
                  lineHeight: '18px',
                  padding: '4px 16px',
                  borderRadius: '20px',
                  color: item.category === 'MARKETING' ? '#57BE99' : item.category === 'SALES' ? '#D93855' : '#3A84C9',
                  border: `1px solid ${item.category === 'MARKETING' ? '#57BE99' : item.category === 'SALES' ? '#D93855' : '#3A84C9'}`,
                  whiteSpace: 'nowrap'
                }}
              >
                {item.type.toLowerCase().replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase())}
              </Typography>
            )}
            {item.metadata && currentTab === 'approved' && (
              <Box
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '8px',
                  marginLeft: '20px'
                }}
              >
                <Avatar
                  src={item.metadata.userImageUrl}
                  alt={item.metadata.userName}
                  style={{
                    width: '28px',
                    height: '28px'
                  }}
                />
                <Box>
                  <Typography noWrap style={{ width: '100px', fontSize: '10px', fontWeight: '500', lineHeight: '12px', color: '#64748B' }}>
                    Created by:
                  </Typography>
                  <Typography noWrap style={{ width: '100px', fontSize: '12px', fontWeight: '500', lineHeight: '15px' }}>
                    {item.metadata.userName}
                  </Typography>
                </Box>
              </Box>
            )}
          </Box>
        </TableCell>
        {/* <TableCell
          align="center"
          className={classes.tableCell}
          style={{
            // width: '20%',
          }}
        >
          <Typography
            noWrap
            style={{
              width: 'fit-content',
              fontSize: '12px',
              fontWeight: '500',
              lineHeight: '18px',
              padding: '4px 16px',
              borderRadius: '20px',
              color: item.category === 'MARKETING' ? '#57BE99' : item.category === 'SALES' ? '#D93855' : '#3A84C9',
              border: `1px solid ${item.category === 'MARKETING' ? '#57BE99' : item.category === 'SALES' ? '#D93855' : '#3A84C9'}`,
            }}
          >
            {item.type.toLowerCase().replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase())}
          </Typography>
        </TableCell> */}
        <TableCell
          align="right"
          className={classes.tableCell}
          style={{
            minWidth: '200px',
            paddingRight: '8px'
          }}
        >
          <Box className={classes.optionsBox}>
            {!!item.emails?.length && (
              <Tooltip title="Duplicate">
                <IconButton
                  onClick={(event) => {
                    event.stopPropagation();

                    if (!isOnboardingCompleted || !isSubscriptionActive) {
                      setShowActionBlockedModal(true);
                    } else {
                      handleDuplicate({ isCampaign: true, campaignId: item.id })
                    }
                  }}
                >
                  <DuplicateIcon />
                </IconButton>
              </Tooltip>
            )}
            <Tooltip title="Delete">
              <IconButton
                onClick={(event) => {
                  event.stopPropagation();

                  if (!isOnboardingCompleted || !isSubscriptionActive) {
                    setShowActionBlockedModal(true);
                  } else {
                    setDeleteSubject({ isCampaign: true, campaignId: item.id })
                  }
                }}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </Box>
        </TableCell>
      </TableRow>
      {item.expanded && (
        !!item.emails?.length ? (
          item.emails.map((emailItem, index) => (
            <TableRow
              key={emailItem.id + index}
              className={classes.expandedTableRow}
              onClick={(event) => {
                event.stopPropagation();

                if (!isOnboardingCompleted || !isSubscriptionActive) {
                  setShowActionBlockedModal(true);
                } else {
                  history.push(`/tactical-outreach/email?campaign_id=${emailItem.campaignId}&email_id=${emailItem.id}`)
                }
              }}
            >
              <TableCell
                align="left"
                className={classes.expandedTableCell}
              >
                <Box className={classes.expandedTableCellMain}>
                  <Box />
                  <Box />
                  <Box />
                  <LatterIcon className={classes.latterIcon} />
                  <Box className={classes.emailContentBox}>
                    <Typography noWrap className={classes.emailContentTitle}>
                      {/* {trimTextByCharacters(emailItem.subject, 30)} */}
                      {emailItem.subject}
                    </Typography>
                    <Typography className={classes.emailContentDate}>
                      Created: {moment.utc(emailItem.createdAt).tz(userTimezone).format('DD-MMM-YYYY HH:mm')}
                    </Typography>
                  </Box>
                </Box>
              </TableCell>
              <TableCell
                align="left"
                className={classes.expandedTableCell}
                style={{
                  // width: '45%',
                }}
              >
                <Typography className={classes.emailContentText}>
                  {trimTextByCharacters(
                    isHTML(emailItem.content)
                      ? convertHTMLToText(emailItem.content)
                      : convertMarkdownToText(emailItem.content),
                    140
                  )
                  }
                </Typography>
              </TableCell>
              {/* <TableCell
                align="center"
                className={classes.expandedTableCell}
                style={{
                  // width: '20%',
                }}
              /> */}
              <TableCell
                align="right"
                className={classes.expandedTableCell}
                style={{
                  paddingRight: '8px',
                  // width: '10%',
                }}
              >
                <Box className={classes.optionsBox}>
                  <Tooltip title="Edit">
                    <IconButton
                      onClick={(event) => {
                        event.stopPropagation();

                        if (!isOnboardingCompleted || !isSubscriptionActive) {
                          setShowActionBlockedModal(true);
                        } else {
                          history.push(`/tactical-outreach/email?campaign_id=${emailItem.campaignId}&email_id=${emailItem.id}`)
                        }
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Duplicate">
                    <IconButton
                      onClick={(event) => {
                        event.stopPropagation();

                        if (!isOnboardingCompleted || !isSubscriptionActive) {
                          setShowActionBlockedModal(true);
                        } else {
                          handleDuplicate({ isCampaign: false, campaignId: emailItem.campaignId, emailId: emailItem.id })
                        }
                      }}
                    >
                      <DuplicateIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Delete">
                    <IconButton
                      disabled={item.emails?.length <= 1}
                      onClick={(event) => {
                        event.stopPropagation();

                        if (!isOnboardingCompleted || !isSubscriptionActive) {
                          setShowActionBlockedModal(true);
                        } else {
                          setDeleteSubject({ isCampaign: false, campaignId: emailItem.campaignId, emailId: emailItem.id })
                        }
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
              </TableCell>
            </TableRow>
          ))
        ) : (
          <Box className={classes.noEmailsBox}>
            <Typography className={classes.noEmailsText}>
              No emails
            </Typography>
          </Box>
        )
      )}
    </>
  );
};

export default CampaignTableRow;
